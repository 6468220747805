import { createI18n, I18nOptions } from 'vue-i18n'
import vi from './vn-VI.json'
import en from './en-US.json'

export function getLanguage () {
  const chooseLanguage = sessionStorage.getItem('st-lg')
  if (chooseLanguage) {
    return chooseLanguage
  }
  // if has not choose language
  return 'vi'
}
type MessageSchema = typeof vi

const i18n = createI18n<I18nOptions, [MessageSchema], 'vi' | 'en'>({
  legacy: false,
  locale: getLanguage(),
  fallbackLocale: 'vi',
  messages: {
    en,
    vi
  }
})
export default i18n
