import request from '@/utils/request'

/**
 * duyetvbd
 */

// Lan dau truy cap trang
export function sttrack (data: any) {
  return request({
    url: 'sttrkdt',
    method: 'post',
    data
  })
}
// Tiep tuc tracking page
export function ctntrack (data: any) {
  return request({
    url: 'ctntrkdt',
    method: 'post',
    data
  })
}
