
import { Options, Vue } from 'vue-class-component'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import { useToast } from 'primevue/usetoast'
import Toast from 'primevue/toast'
import { sshareDatasource } from '@/api/datasource'
import { validEmail } from '@/utils/validate'

@Options({
  components: { InputText, Button, Toast },
  props: {
    sender: {
      type: Object
    },
    page: {
      type: Object
    }
  },
  data () {
    return {
      gForm: {},
      toast: useToast(),
      lmails: [{ m: '' }, { m: '' }, { m: '' }],
      lQuery: {
        pageid: 'home',
        pagetitle: 'vi'
      }
    }
  },
  watch: {
    sender: function (newvl, olvl) {
      this.gForm.name = newvl.name
      this.gForm.email = newvl.email
    }
  },
  methods: {
    utracking (vl: any) {
      this.$emit('utracking', vl)
    },
    addEmail () {
      this.lmails.push({ m: '' })
    },
    validEmail,
    Sendsharing () {
      if (this.sender && this.sender.name && this.sender.email) {
        this.sender.lmails = this.lmails
        var chkempty = true
        var chkvalid = true
        var vm = this
        this.lmails.forEach((item: any) => {
          if (item.m) {
            chkempty = false
            if (!vm.validEmail(item.m)) {
              chkvalid = false
            }
          }
        })
        if (chkempty || !chkvalid) {
          this.toast.add({ severity: 'warning', summary: 'Thông báo', detail: 'Bạn chưa nhập Email hoặc địa chỉ Email không đúng định dạng!', life: 3000 })
        } else {
          sshareDatasource(this.sender).then((res) => {
            this.toast.add({ severity: 'success', summary: 'Thông báo', detail: 'Cảm ơn Ban đã Chia sẻ Nền tảng EBSC đến mọi người!', life: 3000 })
          })
        }
      }
    }
  }
})
export default class Sharingdata extends Vue {
  msg!: string
}
