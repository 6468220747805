import { createStore } from 'vuex'
import {
  lProduct
} from '@/api/product'
import {
  lSection
} from '@/api/section'

const store = createStore({
  modules: {
    product: {
      namespaced: true,
      state: {
        ProductList: { items: [], total: 0 },
        ProductTemp: {}
      },
      mutations: {
        SET_LIST: (state: any, data: any) => {
          state.ProductList.items = data.data
          state.ProductList.total = data.total
        },
        SET_TEMP: (state: any, data: any) => {
          state.ProductTemp = data.data
        }
      },
      actions: {
        l ({ commit }, params = null) {
          return new Promise((resolve, reject) => {
            lProduct(params).then(async (res: any) => {
              if (res) {
                await commit('SET_LIST', res)
              } else {
                await commit('SET_LIST', { items: [], total: 0 })
              }
              await resolve(res)
            })
          })
        }
      }
    },
    section: {
      namespaced: true,
      state: {
        SectionList: { items: [], total: 0 },
        SectionTemp: {}
      },
      mutations: {
        SET_LIST: (state: any, data: any) => {
          state.SectionList.items = data.data
          state.SectionList.total = data.total
        },
        SET_TEMP: (state: any, data: any) => {
          state.SectionTemp = data.data
        }
      },
      actions: {
        l ({ commit }, params = null) {
          return new Promise((resolve, reject) => {
            lSection(params).then(async (res: any) => {
              if (res) {
                await commit('SET_LIST', res)
              } else {
                await commit('SET_LIST', { items: [], total: 0 })
              }
              await resolve(res)
            })
          })
        }
      },
      modules: {
      }
    }
  }
})
export default store
