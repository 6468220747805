<template>
  <Card  class="md:h-28rem">
  <template #title>
      {{title}}
  </template>
  <template #content>
    <div v-if="crshow.qtype=='button'"  class="md:h-18rem">
      <div v-for="(sitem,index) in crshow.answer" :key="index">
        <div class="mt-2"><Button :label="(lg === 'en' && sitem.titleen)?sitem.titleen:sitem.title" @click="nextbt(sitem)"  class="p-2 p-button-warning w-full" /></div>
      </div>
    </div>
    <div v-else-if="crshow.qtype=='product'||crshow.qtype=='checkbox'" class="h-18rem">
      <div class="h-16rem overflow-scroll overflow-x-hidden">
              <div v-for="(sitem,sindex) in crshow.answer" :key="sindex" class="field-checkbox">
              <Checkbox :id="sitem.code" :value="sitem.code" v-model="svdata[crshow.code]" />
               <label :for="sitem.code" v-html="sitem.title" class="text-left"></label>
              </div>
      </div>
              <div class="mt-4"><Button :label="t('tieptuc')" @click="next()" icon="pi pi-arrow-right" iconPos="right" class="p-button-warning" /></div>
              </div>
  </template>
  </Card>
</template>

<script>
import { ref, reactive, defineComponent } from 'vue'
import { lProduct } from '@/api/product'
import Card from 'primevue/card'
import Button from 'primevue/button'
import Checkbox from 'primevue/checkbox'
import { useI18n } from 'vue-i18n'
import { sdChatbot, glChatbot, slChatbot } from '@/api/datasource'
import { mapGetters } from 'vuex'
export default defineComponent({
  components: { Card, Button, Checkbox },
  props: {
    code: {
      type: String,
      default: ''
    },
    pageid: {
      type: String,
      default: ''
    },
    tkcode: {
      type: String,
      default: ''
    },
    slug: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    srow: {
      type: Number,
      default: 6
    }
  },
  setup () {
    const { t } = useI18n()
    return { t }
  },
  data () {
    return {
      svdata: {},
      lpds: [],
      layout: 'list',
      url: '',
      gslug: '',
      tkvl: '',
      nextstep: '',
      cbitem: {},
      dfdata: [],
      lg: 'vi',
      lchatbots: [],
      lwchatbots: [],
      crshow: {},
      iconPos: 'left'
    }
  },
  computed: {
    ...mapGetters({
      ProductList: 'ProductList'
    })
  },
  created () {
    this.tkvl = this.tkcode
    this.gList()
  },
  watch: {
    slug: function (nvl, oldvl) {
      return nvl
    },
    tkcode: function (nvl, oldvl) {
      return nvl
    }
  },
  mounted () {
    this.tkvl = this.tkcode
  },
  methods: {
    async gList () {
      var gss = sessionStorage.getItem('st-acpage')
      const sendData = { cbcode: this.code, rsession: gss, cbicode: this.nextstep, dfdata: this.dfdata, lg: this.lg }
      glChatbot(sendData).then((res) => {
        if (res && res.data.rdata) {
          this.lchatbots = res.data.rdata
          this.cbitem = res.data.qdata
          var vm = this
          this.crshow = this.lchatbots[0]
          if (this.crshow.qtype === 'product' || this.crshow.qtype === 'checkbox') {
            var tmpvl = this.crshow.result ? this.crshow.result : []
            vm.svdata[this.crshow.code] = tmpvl
          }
        }
      })
    },
    nextbt (vl) {
      var nextstep = vl.nextstep ? vl.nextstep : (vl.ldata.nextstep ? vl.ldata.nextstep : '')
      var gss = sessionStorage.getItem('st-acpage')
      this.crshow.result = vl
      var ldata = { cbitem: vl.nextstep, dfdata: this.dfdata, lobject: this.crshow }
      this.$emit('openchatbot', this.code, ldata)
    },
    next () {
      this.dfdata = this.svdata[this.crshow.code]
      var nextstep = this.cbitem.ldata.nextstep ? this.cbitem.ldata.nextstep : ''
      var ldata = { cbitem: nextstep, dfdata: this.dfdata, lobject: {} }
      this.$emit('openchatbot', this.code, ldata)
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
        .card {
            background: #ffffff;
            padding: 2rem;
            box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
            border-radius: 4px;
            margin-bottom: 2rem;
        }
        .p-dropdown {
            width: 14rem;
            font-weight: normal;
        }

        .product-name {
            font-size: 1.5rem;
            font-weight: 700;
        }

        .product-description {
            margin: 0 0 1rem 0;
        }

        .product-category-icon {
            vertical-align: middle;
            margin-right: .5rem;
        }

        .product-category {
            font-weight: 600;
            vertical-align: middle;
        }

        .product-list-item {
            display: flex;
            align-items: center;
            padding: 1rem;
            width: 100%;
        }

        .product-list-item img {
            width: 150px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            margin: 1rem;
        }

        .product-list-item .product-list-detail {
            flex: 1 1 0;
        }

        .product-list-item .p-rating {
            margin: 0 0 .5rem 0;
        }

        .product-list-item .product-price {
            font-size: 1.5rem;
            font-weight: 600;
            margin-bottom: .5rem;
            align-self: flex-end;
        }

        .product-list-item .product-list-action {
            display: flex;
            flex-direction: column;
        }

        .product-list-item .p-button {
            margin-bottom: .5rem;
        }

        .product-grid-item {
            margin: .5rem;
            border: 1px solid var(--surface-border);
        }

        .product-grid-item .product-grid-item-top,
        .product-grid-item .product-grid-item-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .product-grid-item img {
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            margin: 2rem 0;
        }

        .product-grid-item .product-grid-item-content {
            text-align: center;
        }

        .product-grid-item .product-price {
            font-size: 1.5rem;
            font-weight: 600;
        }

        @media screen and (max-width: 576px) {
            .product-list-item {
                flex-direction: column;
                align-items: center;
            }

            .product-list-item .product-list-item img {
                margin: 2rem 0;
            }

            .product-list-item .product-list-detail {
                text-align: center;
            }

            .product-list-item .product-price {
                align-self: center;
            }

            .product-list-item .product-list-action {
                display: flex;
                flex-direction: column;
            }

            .product-list-item .product-list-action {
                margin-top: 2rem;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }
        }
        </style>
