<template>
    <div class="min-w-min">
        <div v-if="showMessage">
            <div class="flex align-items-center flex-column pt-6 px-3">
                <i class="pi pi-check-circle" :style="{fontSize: '5rem', color: 'var(--green-500)' }"></i>
                <h3>Bạn đã gửi liên hệ thành công!</h3>
                <h4>Chúng tôi sẽ liên hệ lại trong vòng 24h</h4>
                <p :style="{lineHeight: 1.5, textIndent: '1rem'}">
                  Vui lòng chia sẻ với bạn bè để nhận chiết khấu cho lần đặt hàng tiếp theo.
                </p>
            </div>
            <div class="flex justify-content-center">
                <Button label="Chia sẻ" @click="toggleDialog" class="p-button-text" />
            </div>
        </div>
        <div class="grid" v-if="!showMessage">
            <div class="col-12 md:col-12 lg:col-12 xl:col-12 max-w-screen" v-if="lsurveys" id="l-survey">
              <Galleria :value="lsurveys.result" :showThumbnails="false" :showIndicators="false" v-model:activeIndex="activeIndex">
              <template #item="slotProps">
              <div class="mb-3">
              <h3>{{(lg === 'en' && slotProps.item.titleen) ? slotProps.item.titleen:slotProps.item.title}}</h3>
              </div>
              <div v-if="slotProps.item.qtype=='product'||slotProps.item.qtype=='checkbox'">
              <div v-for="(sitem,index) in slotProps.item.answer" :key="index" class="field-checkbox">
              <Checkbox :id="sitem.code" :value="sitem.code" v-model="svdata[slotProps.item.code]" class="w-full"/>
               <label :for="sitem.code">{{sitem.title}}</label>
              </div>
              <div class="mt-4"><Button :label="t('trangtruoc')" @click="prev"  icon="pi pi-arrow-left" class="mr-3 p-button-secondary"/><Button :label="t('tieptuc')" @click="next(slotProps.item)" icon="pi pi-arrow-right" iconPos="right" class="ml-2 p-button-success" /></div>
              </div>
              <div v-else-if="slotProps.item.qtype=='form'">
              <div v-for="(sitem,index) in slotProps.item.answer" :key="index">
              <div v-if="sitem.type=='7'" class="field-checkbox">
               <Checkbox :id="sitem.code" :value="sitem.code" v-model="svdata[sitem.code]" />
               <label :for="sitem.code">{{sitem.title}}</label>
               </div>
               <div v-if="sitem.type=='2'||sitem.type=='3'" class="p-float-label">
               <div class="text-pink-600">{{emessage}}</div>
              <InputText :id="sitem.code" v-model="svdata[sitem.code]"  class="w-full"/>
               </div>
              <div v-if="sitem.type=='4'" >
               <Textarea :id="sitem.code" v-model="svdata[sitem.code]" rows="5" cols="80" class="w-full" />
               </div>
              </div>
              <div class="mt-4"><Button :label="t('trangtruoc')" @click="prev"  icon="pi pi-arrow-left" class="mr-3 p-button-secondary"/><Button :label="t('tieptuc')" @click="next(slotProps.item)" icon="pi pi-arrow-right" iconPos="right" class="ml-2 p-button-warning" /></div>
              </div>
              <div v-else-if="slotProps.item.qtype=='radio'">
              <div v-for="(sitem,index) in slotProps.item.answer" :key="index" class="p-float-label mt-4">
               <RadioButton :id="sitem.code" v-model="svdata[slotProps.item.code]" />
               <label :for="sitem.code">{{sitem.title}}</label>
              </div>
              <div class="mt-4"><Button :label="t('trangtruoc')" @click="prev"  icon="pi pi-arrow-left" class="mr-3 p-button-secondary"/><Button :label="t('tieptuc')" @click="next(slotProps.item)" icon="pi pi-arrow-right" iconPos="right" class="ml-2 p-button-warning" /></div>
              </div>
              <div v-else-if="slotProps.item.qtype=='contact'">
              <div v-for="(sitem,index) in slotProps.item.answer" :key="index" class="p-float-label mt-4">
               <InputText :id="sitem.code" v-model="svdata[sitem.code]"  class="w-full"/>
               <label :for="sitem.code">{{sitem.title}}</label>
              </div>
              <div class="mt-4"><Button :label="t('trangtruoc')" @click="prev"  icon="pi pi-arrow-left" class="mr-3 p-button-secondary"/><Button :label="t('tieptuc')" @click="next(slotProps.item)" icon="pi pi-arrow-right" iconPos="right" class="ml-2 p-button-warning" /></div>
              </div>
              <div v-else-if="slotProps.item.qtype=='sharing'">
                <SendSharingF @Sendsharing="Sendsharing" :sender="sender" />
              </div>
              </template>
              </Galleria>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from 'primevue/inputtext'
import RadioButton from 'primevue/radiobutton'
import Checkbox from 'primevue/checkbox'
import Button from 'primevue/button'
import Textarea from 'primevue/textarea'
import Galleria from 'primevue/galleria'
import { ctntrack } from '@/api/tracking'
import SendSharingF from '@/components/sendsharing'
import { validEmail, validPhone, validURL } from '@/utils/validate'
import { useI18n } from 'vue-i18n'
import { lDatasource, sDatasource, glSurvey, slSurvey } from '@/api/datasource'
export default {
  components: { InputText, Checkbox, Button, Galleria, RadioButton, SendSharingF, Textarea },
  props: {
    psend: { type: String, default: '' },
    esend: { type: String, default: '' }
  },
  setup () {
    const { t } = useI18n()
    return { t }
  },
  data () {
    return {
      postForm: {},
      lpds: [],
      liwants: [],
      svdata: {},
      activeIndex: 0,
      date: null,
      accept: null,
      submitted: false,
      showMessage: false,
      emessage: '',
      sender: {},
      lsurveys: {},
      lg: 'vi',
      lQuery: {
        pageid: '',
        pagetitle: ''
      }
    }
  },
  watch: {
    psend: function (newvl, oldvl) {
      this.lQuery = newvl
    },
    esend: function (newvl, oldvl) {
      this.email = newvl
    }
  },
  created () {
    var glg = sessionStorage.getItem('st-lg')
    this.lg = glg
    this.lQuery = this.psend
    this.email = this.esend
    this.gListSV()
  },
  methods: {
    async gListSV () {
      var gss = sessionStorage.getItem('st-acpage')
      const sendData = { svcode: 'kskhmsp', rsession: gss }
      glSurvey(sendData).then((res) => {
        if (res && res.data.rdata) {
          this.lsurveys = res.data.rdata
          var vm = this
          console.log(this.lsurveys)
          this.lsurveys.result.forEach((item) => {
            if (item.qtype === 'product' || item.qtype === 'checkbox') {
              vm.svdata[item.code] = item.result ? item.result : []
            } else if (item.qtype === 'contact' || item.qtype === 'form') {
              item.answer.forEach((sitem) => {
                vm.svdata[sitem.code] = sitem.result ? sitem.result : ''
              })
            }
          })
        }
      })
    },
    next (vl) {
      var gss = sessionStorage.getItem('st-acpage')
      var chkvalid = true
      this.emessage = ''
      if (vl.qtype === 'contact' || vl.qtype === 'form') {
        var tmprs = []
        var vm = this
        vl.answer.forEach((item) => {
          tmprs.push({ code: item.code, value: vm.svdata[item.code] })
          if (item.rlcode === 'email') {
            if (vm.validEmail(vm.svdata[item.code])) {
              sessionStorage.setItem('st-email', vm.svdata[item.code])
            } else {
              chkvalid = false
            }
          }
          if (item.rlcode === 'phone') {
            if (!vm.validPhone(vm.svdata[item.code])) {
              chkvalid = false
            }
          }
          if (item.rlcode === 'website') {
            if (!vm.validURL(vm.svdata[item.code])) {
              chkvalid = false
            }
          }
          if (item.rlcode === 'name') {
            sessionStorage.setItem('st-name', vm.svdata[item.code])
          }
        })
        if (chkvalid) {
          vl.result = tmprs
        }
      } else {
        vl.result = this.svdata[vl.code]
      }
      if (chkvalid) {
        const sendData = { svcode: 'kskhmsp', ldata: vl, rsession: gss }
        slSurvey(sendData).then((res) => {
          this.activeIndex = (this.activeIndex === this.lsurveys.result.length - 1) ? this.activeIndex : this.activeIndex + 1
          if (this.activeIndex === this.lsurveys.result.length - 1) {
            this.$emit('donesurvey', 1)
          }
        })
      } else {
        if (vl.answer[0].rlcode === 'email') {
          this.emessage = 'Email không chính xác!'
        }
        if (vl.answer[0].rlcode === 'phone') {
          this.emessage = 'Số điện thoại không chính xác!'
        }
        if (vl.answer[0].rlcode === 'website') {
          this.emessage = 'Website không chính xác!'
        }
      }
    },
    prev () {
      this.activeIndex = (this.activeIndex === 0) ? 0 : this.activeIndex - 1
    },
    validEmail,
    validPhone,
    validURL,
    utracking (vl) {
      var gss = sessionStorage.getItem('st-acpage')
      var gtdata = { rsession: gss, pageid: this.lQuery.pageid, pagetitle: this.lQuery.pagetitle, elementid: vl.eid }
      ctntrack(gtdata)
    }
  }
}
</script>
<style lang="scss" scoped>
.form-demo {
  .card {
    min-width: 450px
    form {
      margin-top: 2rem
    }
    .field {
      margin-bottom: 1.5rem
    }
  }
  @media screen and (max-width: 960px) {
    .card {
      width: 80%
    }
  }
}
</style>
<style>
#l-survey .p-galleria-item{
  display: block !important;
}
</style>
