<template>
  <div>
<Message severity="success" class="w-3 fixed bottom-0 left-0" :life="1000" :sticky="sct">
{{ amsg.title }}
</Message>
</div>
</template>
<script>
import Button from 'primevue/button'
import { Options, Vue } from 'vue-class-component'
import Message from 'primevue/message'
@Options({
  components: {
    Message, Button
  },
  data () {
    return {
      sct: false,
      gid: 0,
      polling: null,
      amsg: {},
      lmessages: [{ image: 'home', title: 'Công ty DEF', content: 'Đã đăng ký thành công' }, { image: 'home', title: 'Công ty ABC', content: 'Đã thanh toán thành công' }]
    }
  },
  mounted () {
    this.polling = setInterval(this.changeMsg(), 1000)
  },
  watch: {
    gid (nvl, oldvl) {
      clearInterval(this.polling)
    }
  },
  beforeDestroy () {
    clearInterval(this.polling)
  },
  methods: {
    gList () {
      this.$store.dispatch('section/l', this.lQuery).then((res) => {
        this.lsections = res.data
      })
    },
    showInfo () {
      this.polling = setInterval(this.changeMsg(), 2000)
    },
    changeMsg () {
      if (this.gid === this.lmessages.length) {
        this.gid = 0
      }
      this.amsg = this.lmessages[this.gid]
      console.log(this.amsg)
      this.gid += 1
    }
  }
})
export default class Leftcornercontact extends Vue {}
</script>
<style>
.actbtn-holder-float {
  width: 85px;
  height: 85px;
  border: 1px solid lightgray;
  border-radius: 5px;
  background-color: rgba(255,255,255,.7);
  left: 5px;
}
.actbtn-item-getcall {
    font-size: 45px !important;
    color: green;
}
.actbtn-item-getcall i{
    font-size: 45px !important;
    line-height: 80px !important;
}
.actbtn-item-getcall:hover{
  cursor:pointer;
}
/* Firefox old*/
@-moz-keyframes blinkimg {
    0% {
        color:red;
    }
    50% {
        color:yellow;
    }
    100% {
        color:red;
    }
}
@-webkit-keyframes blinkimg {
    0% {
        color:red;
    }
    50% {
        color:yellow;
    }
    100% {
        color:red;
    }
}
/* IE */
@-ms-keyframes blinkimg {
  0% {
        color:red;
    }
    50% {
        color:yellow;
    }
    100% {
        color:red;
    }
}
/* Opera and prob css3 final iteration */
@keyframes  blinkimg {
    0% {
        color:red;
    }
    50% {
        color:yellow;
    }
    100% {
        color:red;
    }
}
.blink-image {
    -moz-animation: blinkimg normal 2s infinite; /* Firefox */
    -webkit-animation: blinkimg normal 2s infinite; /* Webkit */
    -ms-animation: blinkimg normal 2s infinite; /* IE */
    animation: blinkimg normal 2s infinite; /* Opera and prob css3 final iteration */
}
</style>
