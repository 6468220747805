import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import { useCookies } from 'vue3-cookies'
import {
  sttrack, ctntrack
} from '@/api/tracking'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta: { title: 'Trang chủ' },
    component: Home
  },
  {
    path: '/home2',
    name: 'Home2',
    meta: { title: 'Trang chủ 2' },
    component: () => import(/* webpackChunkName: "about" */ '../views/Home2.vue')
  },
  {
    path: '/khach-hang',
    name: 'Khachhang',
    meta: { title: 'Khách hàng' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Customer.vue')
  },
  {
    path: '/:lg/khach-hang/:dm/:id',
    name: 'chitietKhachhang',
    meta: { title: 'Chi tiết Khách hàng' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Customerdetail.vue')
  },
  {
    path: '/:lg/khach-hang/:id',
    meta: { title: 'Danh mục khách hàng' },
    name: 'danhmuckhachhang',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Customercat.vue')
  },
  {
    path: '/san-pham',
    name: 'sanpham',
    meta: { title: 'Sản phẩm' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Pcat.vue')
  },
  {
    path: '/danh-muc-san-pham/:id',
    name: 'danhmucsanpham',
    meta: { title: 'Danh mục sản phẩm' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Pcatdetail.vue')
  },
  {
    path: '/san-pham/:id',
    name: 'chitietsanpham',
    meta: { title: 'Chi tiết sản phẩm' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Productdetail.vue')
  },
  {
    path: '/gia',
    name: 'giaban',
    meta: { title: 'Giá bán' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Customer.vue')
  },
  {
    path: '/flashsale',
    name: 'flashsale',
    meta: { title: 'Flash sale' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Flashsale.vue')
  },
  {
    path: '/cong-ty',
    name: 'company',
    meta: { title: 'Công ty' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    meta: { title: 'Blog' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Blog.vue')
  },
  {
    path: '/:lg/blog/danh-muc/:dm',
    name: 'Blogcat',
    meta: { title: 'Danh mục Blog' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Blogcat.vue')
  },
  {
    path: '/:lg/blog/bai-viet/:id',
    name: 'Blogdetail',
    meta: { title: 'Chi tiết bài viết' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Blogdetail.vue')
  },
  {
    path: '/tim-kiem/:dt',
    name: 'timkiem',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Search.vue')
  },
  {
    path: '/sharing',
    name: 'sharing',
    meta: { title: 'Chia sẻ' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Sharing.vue')
  },
  {
    // path: "*",
    path: '/:catchAll(.*)',
    name: 'NotFound',
    meta: { title: 'Lỗi 404' },
    component: () => import(/* webpackChunkName: "about" */ '../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  const { cookies } = useCookies()
  const gck = cookies.get('test-key')
  const gdate = new Date()
  const vl = Math.floor(Math.random() * 100 + 1)
  const gss = sessionStorage.getItem('st-acpage')
  const lg = sessionStorage.getItem('st-lg')
  let gtdata = {}
  if (!lg) {
    sessionStorage.setItem('st-lg', 'vi')
  }
  if (!gck) {
    cookies.set('test-key', vl + 'a' + gdate.getTime())
  }
  if (!gss) {
    sessionStorage.setItem('st-acpage', vl + 's' + gdate.getTime())
    gtdata = { rsession: vl + 's' + gdate.getTime(), pageid: to.path, pagetitle: to.meta.title }
    sttrack(gtdata)
  } else {
    gtdata = { rsession: gss, pageid: to.path, pagetitle: to.meta.title }
    ctntrack(gtdata)
  }
  next()
})
export default router
