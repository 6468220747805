
import { Options, Vue } from 'vue-class-component'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import Carousel from 'primevue/carousel'
import { mapGetters } from 'vuex'
@Options({
  components: { Button, Carousel, Dropdown },
  props: {
    code: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      lcustomers: [],
      responsiveOptions: [
        {
          breakpoint: '1024px',
          numVisible: 4,
          numScroll: 4
        },
        {
          breakpoint: '600px',
          numVisible: 2,
          numScroll: 2
        },
        {
          breakpoint: '480px',
          numVisible: 1,
          numScroll: 1
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ProductList: 'ProductList'
    })
  },
  created () {
    this.gList()
  },
  methods: {
    async gList () {
      /* const sendData = {}
        await this.$store.dispatch('product/l', sendData).then(async (res: any) => {
        if (res) {
          this.lpds = res.data.data
        }
      })
      */
      var tmp = [
        {
          title: 'An Binh Bank',
          img: 'logoabbank.png'
        },
        {
          title: 'Genco2',
          img: 'logoevngenco2.png'
        },
        {
          title: 'Him Lam',
          img: 'logohimlam.png'
        },
        {
          title: 'KTG',
          img: 'logoktg.png'
        },
        {
          title: 'Vinh Tuong',
          img: 'logovinhtuong.png'
        }
      ]
      this.lcustomers = tmp
    },
    onSortChange (event: any) {
      const value = event.value.value
      const sortValue = event.value
      if (value.indexOf('!') === 0) {
        this.sortOrder = -1
        this.sortField = value.substring(1, value.length)
        this.sortKey = sortValue
      } else {
        this.sortOrder = 1
        this.sortField = value
        this.sortKey = sortValue
      }
    }
  }
})
export default class Block extends Vue {
  msg!: string
}
