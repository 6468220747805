import request from '@/utils/request'

/**
 * duyetvbd
 */

// fetch list
export function lDatasource (query: any) {
  return request({
    url: '/ldtsfront',
    method: 'get',
    params: query
  })
}
// Danh sach tat ca cac bai viet
export function gDatasource (query: any) {
  return request({
    url: '/gprdatcsssdf',
    method: 'get',
    params: query
  })
}
// Danh sach tat ca cac bai viet
export function sDatasource (data: any) {
  return request({
    url: '/sdtsadtvldf',
    method: 'post',
    data
  })
}
// Danh sach tat ca cac bai viet
export function scDatasource (data: any) {
  return request({
    url: '/scdtsdtvldf',
    method: 'post',
    data
  })
}
// Cập nhật danh sách khuyến mãi
export function uDiscount (data: any) {
  return request({
    url: '/udcfshpc',
    method: 'post',
    data
  })
}
// Cập nhật danh sách khuyến mãi
export function lDiscount (query: any) {
  return request({
    url: '/ldcflsales',
    method: 'get',
    params: query
  })
}
// Danh sach tat ca cac bai viet
export function gfDatasource (query: any) {
  return request({
    url: '/gfdtsabbsdf',
    method: 'get',
    params: query
  })
}
// Danh sach tat ca cac bai viet
export function sfDatasource (data: any) {
  return request({
    url: '/sfdtsfdvldf',
    method: 'post',
    data
  })
}
// Save share data sources
export function sshareDatasource (data: any) {
  return request({
    url: '/ssharedtsfvdf',
    method: 'post',
    data
  })
}
// Danh sach tat ca cac bai viet
export function glSurvey (query: any) {
  return request({
    url: '/glsvonepsf',
    method: 'get',
    params: query
  })
}
// Danh sach tat ca cac bai viet
export function slSurvey (data: any) {
  return request({
    url: '/slsvgonopsf',
    method: 'post',
    data
  })
}
// Danh sach tat ca cac bai viet
export function sdSurvey (data: any) {
  return request({
    url: '/sdsvgopphtpsf',
    method: 'post',
    data
  })
}
// Danh sach tat ca cac bai viet
export function glChatbot (query: any) {
  return request({
    url: '/glcbvsfsdf',
    method: 'get',
    params: query
  })
}
// Danh sach tat ca cac bai viet
export function slChatbot (data: any) {
  return request({
    url: '/slcbinfmt',
    method: 'post',
    data
  })
}
// Danh sach tat ca cac bai viet
export function sdChatbot (data: any) {
  return request({
    url: '/sdcbdssdfsdf',
    method: 'post',
    data
  })
}
// Danh sach tat ca cac bai viet
export function sclmDatasource (data: any) {
  return request({
    url: '/sclmdtsaz',
    method: 'post',
    data
  })
}
