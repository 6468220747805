
import { Options, Vue } from 'vue-class-component'
import Section from '@/components/section.vue' // @ is an alias to /src
import { ctntrack } from '@/api/tracking'
import { glSection } from '@/api/section'
@Options({
  components: {
    Section
  },
  data () {
    return {
      lpages: [{ code: 'home', slug: '' }, { code: 'congty', slug: 'cong-ty' }],
      lQuery: {
        domain: 'kpibsc.com',
        page: 'home',
        lang: 'vi',
        sscode: []
      },
      gstart: 0,
      lsdata: [],
      lsections: []
    }
  },
  created () {
    this.lQuery.lang = sessionStorage.getItem('st-lg')
    var gpath = this.$route.path.split('/')
    this.lQuery.domain = window.location.host
    this.gpage(gpath)
  },
  watch: {
    $route: function (newvl: any, oldvl: any) {
      var gpath = newvl.path.split('/')
      this.lQuery.lang = sessionStorage.getItem('st-lg')
      this.gstart = 0
      this.lsdata = []
      this.lsections = []
      this.gpage(gpath)
    }
  },
  mounted () {
    this.getNextDt()
  },
  methods: {
    getNextDt () {
      window.onscroll = () => {
        const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight
        if (bottomOfWindow && this.gstart > 0) {
          this.gList()
        }
      }
    },
    gList () {
      var tmpdata: any = []
      var tmpdata1: any = []
      var stvl: any = 0
      if (this.lsdata && this.gstart < 2) {
        this.lsdata.forEach((item: any) => {
          if (stvl < 2) {
            tmpdata.push(item.code)
          } else {
            tmpdata1.push(item.code)
          }
          stvl += 1
        })
        console.log(this.lsdata)
        if (this.gstart === 0) {
          this.lQuery.sscode = tmpdata
        } else {
          this.lQuery.sscode = tmpdata1
        }
        this.gstart += 1
        if (this.lQuery.sscode.length > 0) {
          this.$store.dispatch('section/l', this.lQuery).then((res: any) => {
            this.lsections = this.lsections.concat(res.data)
            this.$emit('loadfooter')
          })
        }
      }
    },
    gpage (vl: any) {
      this.lsections = []
      var tmp = 'home'
      if (vl.length > 0) {
        if (vl[1] !== '') {
          var fid = this.lpages.findIndex((v: any) => v.slug === vl[1])
          if (fid !== -1) {
            tmp = this.lpages[fid].code
          }
        }
      }
      this.lQuery.page = tmp
      glSection(this.lQuery).then((res: any) => {
        this.lsdata = res.data
        this.gList()
      })
    },
    utracking (vl: any) {
      var gss = sessionStorage.getItem('st-acpage')
      var to = this.$route
      var gtdata = { rsession: gss, pageid: to.path, pagetitle: to.meta.title, elementid: vl.eid }
      ctntrack(gtdata)
    },
    opencontact (vl: any) {
      vl.pageid = this.$route.path
      vl.pagetitle = this.$route.meta.title
      this.$emit('opencontact', vl)
    },
    openshopcart (vl: any) {
      this.$emit('openshopcart', vl)
    }
  }
})
export default class Home extends Vue {}
