<template>
    <div class="col-12 md:col-6 max-w-screen">
        <div class="grid">
            <div class="col-fixed" style="width:200px">Mã Đăng ký</div>
            <div class="col">
                <b>{{ postForm.dhcode }}</b>
            </div>
        </div>
        <div class="grid">
            <div class="col-fixed" style="width:200px">Họ tên</div>
            <div class="col">
                {{ postForm.name }}
            </div>
        </div>
        <div class="grid">
            <div class="col-fixed" style="width:200px">Email</div>
                <div class="col">
                    {{ postForm.email }}
                </div>
        </div>
        <div class="grid">
            <div class="col-fixed" style="width:200px">Số điện thoại</div>
            <div class="col">
              {{ postForm.phone }}
            </div>
        </div>
        <div class="grid">
            <div class="col-fixed" style="width:200px">Công ty</div>
              <div class="col">
                {{ postForm.congty }}
              </div>
            </div>
        <div class="grid">
            <div class="col-fixed" style="width:200px">Chức vụ</div>
            <div class="col">
              {{ postForm.chucvu }}
            </div>
        </div>
        <div class="grid">
          <div class="col-fixed" style="width:200px">Tổng đơn hàng</div>
          <div class="col">
            {{ formatCurrency(postForm.results.total) }}
          </div>
        </div>
        <div class="grid">
          <div class="col-fixed" style="width:200px">Mã khuyến mãi</div>
          <div class="col">
            {{ postForm.results.kmcode }}
          </div>
        </div>
        <div class="grid">
          <div class="col-fixed" style="width:200px">Khuyến mãi</div>
          <div class="col">
            {{ formatCurrency(postForm.results.discount) }}
          </div>
        </div>
        <div class="grid">
          <div class="col-fixed" style="width:200px">Tổng tiền phải trả</div>
          <div class="col">
            {{ formatCurrency(postForm.results.final) }}
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6 max-w-screen">
        <div class="card">
          <div class="grid">
            <div class="col">{{taikhoan.title }}</div>
          </div>
          <div class="grid">
            <div class="col-fixed" style="width:150px">Ngân hàng</div>
            <div class="col">
              {{taikhoan.nganhang }}
            </div>
          </div>
          <div class="grid">
            <div class="col-fixed" style="width:150px">Chi nhánh</div>
              <div class="col">
                {{taikhoan.chinhanh}}
              </div>
            </div>
          <div class="grid">
            <div class="col-fixed" style="width:150px">Số tài khoản</div>
            <div class="col">
              {{taikhoan.sotaikhoan}}
            </div>
          </div>
          <div class="grid">
            <div class="col-fixed" style="width:150px">Chủ tài khoản</div>
            <div class="col">
              {{taikhoan.chutaikhoan}}
            </div>
          </div>
          <div class="md:flex">
            <img width="320" height="320" src="/img/thanhtoan/QrCodeACB.JPG" alt="ACB" title="Thanh toán qua ACB" class="m-2"/>
            <img width="320" height="320" src="/img/thanhtoan/QrCodeBIDV.JPG" alt="BIDV" title="Thanh toán qua BIDV" class="m-2"/>
          </div>
        </div>
      </div>
    <div class="col-12 max-w-screen">
    <div><h2>Vui lòng nhập Email để chia sẻ thông tin tới bạn bè để nhận chiết khấu!</h2></div>
        <SendSharingF @Sendsharing="Sendsharing" :sender="postForm" />
    </div>
</template>
<script>
import { email, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { lDatasource, sDatasource } from '@/api/datasource'
import { useToast } from 'primevue/usetoast'
import { formatCurrency } from '@/utils/fslist'
import SendSharingF from '@/components/sendsharing'
import Toast from 'primevue/toast'
export default {
  components: { SendSharingF },
  props: {
    gttcn: { type: Object, default: () => { return {} } },
    gdata: { type: Object, default: () => { return {} } },
    psend: { type: String, default: '' },
    esend: { type: String, default: '' }
  },
  setup: () => ({
    v$: useVuelidate()
  }),
  data () {
    return {
      postForm: {
        name: '',
        email: '',
        congty: '',
        chucvu: '',
        phone: '',
        website: '',
        nuser: '',
        iwant: [],
        ndkhac: '',
        results: {},
        lpdvl: []
      },
      lpdvl: [],
      lpds: [],
      ndkhac: '',
      liwants: [],
      date: null,
      accept: null,
      taikhoan: {},
      lemail: '',
      submitted: false,
      showMessage: false
    }
  },
  watch: {
    gttcn: function (newvl, ovl) {
      this.postForm = Object.assign({}, newvl)
    },
    gdata: function (newvl, ovl) {
      this.postForm.results = newvl.result
    }
  },
  countryService: null,
  created () {
    this.gListPD()
    this.gtkinfo()
  },
  methods: {
    async gListPD () {
      this.postForm = Object.assign({}, this.gttcn)
      this.postForm.results = this.gdata.result
    },
    async gtkinfo () {
      await lDatasource({ code: 'taikhoaninfo' }).then(async (res) => {
        if (res) {
          var tmpvl = {}
          res.data.rdata.forEach((item) => {
            tmpvl = item.ldata
          })
          this.taikhoan = tmpvl
        }
      })
    },
    Sendsharing () {
      var vl = {}
      vl.code = 3
      this.$emit('jumpnext', vl)
    },
    formatCurrency
  }
}
</script>
