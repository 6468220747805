import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = {
  class: "card flex align-content-center",
  style: {"height":"150px","width":"auto","margin":"2px","padding":"2px"}
}
const _hoisted_3 = {
  class: "flex align-items-center justify-content-center",
  style: {"width":"260px"}
}
const _hoisted_4 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Carousel = _resolveComponent("Carousel")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Carousel, {
      value: _ctx.lcustomers,
      numVisible: 4,
      numScroll: 1,
      class: "custom-carousel",
      responsiveOptions: _ctx.responsiveOptions,
      circular: true,
      autoplayInterval: 3000
    }, {
      item: _withCtx((slotProps) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("img", {
                src: '/logokhachhang/' + slotProps.data.img ,
                alt: slotProps.data.title
              }, null, 8, _hoisted_4)
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["value", "responsiveOptions"])
  ]))
}