import { createSSRApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './lang'
import { createHead } from '@vueuse/head'
import primevue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import 'primeflex/primeflex.css'
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import '@/styles/style.css'
const head = createHead()
createSSRApp(App).use(ToastService).use(i18n).use(head).use(store).use(router).use(primevue).mount('#app')
