import request from '@/utils/request'

/**
 * duyetvbd
 */

// fetch list
export function lSection (query: any) {
  return request({
    url: '/section/showpage',
    method: 'get',
    params: query
  })
}
// fetch list
export function glSection (query: any) {
  return request({
    url: '/glssdtffsa',
    method: 'get',
    params: query
  })
}
