<template>
  <Card class="w-full mb-2">
    <template #header >
    <div v-html="title" :style="gstyle"></div>
    </template>
        <template #content >
    <div v-html="content" ></div>
    </template>
  </Card>
</template>

<script>
import { Options, Vue } from 'vue-class-component'
import { ref, reactive, defineComponent } from 'vue'
import Card from 'primevue/card'
export default defineComponent({
  components: { Card },
  props: {
    content: {
      type: String,
      default: ''
    },
    tkcode: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    bgcolor: {
      type: String,
      default: 'black'
    }
  },
  setup (prop) {
    const gstyle = ref('font-size: 24px;line-height:66px; font-weight: bold; background-color:' + prop.bgcolor)
    return { gstyle }
  }
})

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
