<template>
  <div class="fixed left-0 top-50">
  <div class="actbtn-holder-float" id="actbtn-container">
  <div class="actbtn-item-holder">
    <div class="actbtn-item-getcall blink-image" @click="opDl">
    <i class="pi pi-phone"></i>
    </div>
  </div>
</div>
<Dialog v-model:visible="sct" :breakpoints="{ '1024px': '50vw', '960px': '75vw', '640px': '100vw' }" :style="{ width: '50vw' }" position="top" :modal="true">
    <div class="grid">
    <div class="col-12 md:col-6 md:col-offset-3">
    <div class="text-center">
        <h5>Vui lòng để lại số điện thoại, chúng tôi sẽ gọi lại ngay sau 5 phút.</h5>
        <form method="post" action="" id="callback-form" novalidate="novalidate" class="text-center">
            <span class="w-full p-input-icon-left m-1">
                <i class="pi pi-user" />
                <InputText type="text" placeholder="Họ tên" id="cb_hoten" v-model="cbhoten" class="w-full" />
            </span>
            <span class="w-full p-input-icon-left m-1">
                <i class="pi pi-phone" />
                <InputText placeholder="Điện thoại" id="cb_sodt" v-model="cbsodt" class="w-full"/>
            </span>
      <Button class="w-full m-1" label="Yêu cầu gọi lại!" icon="pi pi-send" iconPos="left" />
        </form>
        <Divider align="center">
        <p>Liên hệ theo số máy sau:</p>
        </Divider>
        <div class="text-4xl text-pink-600 text-center">+84.705.019.688</div>
    </div>
    </div>
    </div>
</Dialog>
</div>
</template>
<script>
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Divider from 'primevue/divider'
import { Options, Vue } from 'vue-class-component'
import { ctntrack } from '@/api/tracking'
@Options({
  components: {
    Dialog, Button, InputText, Divider
  },
  data () {
    return {
      sct: false,
      lpages: [{ code: 'home', slug: '' }, { code: 'congty', slug: 'cong-ty' }]
    }
  },
  created () {
    var gpath = this.$route.path.split('/')
  },
  methods: {
    opDl () {
      this.sct = true
      this.utracking('btncn')
    },
    utracking (vl) {
      var gtdata = { eid: vl }
      this.$emit('utracking', gtdata)
    }
  }
})
export default class Leftcontact extends Vue {}
</script>
<style>
.actbtn-holder-float {
  width: 85px;
  height: 85px;
  border: 1px solid lightgray;
  border-radius: 5px;
  background-color: rgba(255,255,255,.7);
  left: 5px;
}
.actbtn-item-getcall {
    font-size: 45px !important;
    color: green;
}
.actbtn-item-getcall i{
    font-size: 45px !important;
    line-height: 80px !important;
}
.actbtn-item-getcall:hover{
  cursor:pointer;
}
/* Firefox old*/
@-moz-keyframes blinkimg {
    0% {
        color:red;
    }
    50% {
        color:yellow;
    }
    100% {
        color:red;
    }
}
@-webkit-keyframes blinkimg {
    0% {
        color:red;
    }
    50% {
        color:yellow;
    }
    100% {
        color:red;
    }
}
/* IE */
@-ms-keyframes blinkimg {
  0% {
        color:red;
    }
    50% {
        color:yellow;
    }
    100% {
        color:red;
    }
}
/* Opera and prob css3 final iteration */
@keyframes  blinkimg {
    0% {
        color:red;
    }
    50% {
        color:yellow;
    }
    100% {
        color:red;
    }
}
.blink-image {
    -moz-animation: blinkimg normal 2s infinite; /* Firefox */
    -webkit-animation: blinkimg normal 2s infinite; /* Webkit */
    -ms-animation: blinkimg normal 2s infinite; /* IE */
    animation: blinkimg normal 2s infinite; /* Opera and prob css3 final iteration */
}
</style>
