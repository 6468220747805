// Create axios instance
import axios from 'axios'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // 'http://127.0.0.1:8000',
  timeout: 10000, // Request timeout
  withCredentials: false,
  headers: { 'X-Requested-With': 'XMLHttpRequest', 'Access-Control-Allow-Origin': 'http://127.0.0.1:8000' }
})
export default service
