
import { Options, Vue } from 'vue-class-component'
import sButton from './sbutton.vue'
import sForm from './sform.vue'
import sCard from './scard.vue'
import sProduct from './sproduct.vue'
import sPrice from './sprice.vue'
import sKhachhang from './skhachhang.vue'
import sChatbot from './schatbot.vue'
@Options({
  components: { sButton, sForm, sProduct, sKhachhang, sChatbot, sPrice, sCard },
  props: {
    gdata: {
      type: Object
    },
    page: {
      type: String,
      default: 'home'
    },
    lang: {
      type: String,
      default: 'vi'
    }
  },
  data () {
    return {
      lQuery: {
        page: 'home',
        lang: 'vi'
      }
    }
  },
  mounted () {
    var hsh = this.$route.hash
  },
  methods: {
    utracking (vl: any) {
      this.$emit('utracking', vl)
    },
    opencontact (vl: any) {
      this.$emit('opencontact', vl)
    },
    openshopcart (vl: any) {
      this.$emit('openshopcart', vl)
    },
    openchatbot (vl: any, dt: any) {
      this.$emit('openchatbot', vl, dt)
    }
  }
})
export default class Block extends Vue {
  msg!: string
}
