import request from '@/utils/request'

/**
 * duyetvbd
 */

// fetch list
export function lProduct (query: any) {
  return request({
    url: '/product',
    method: 'get',
    params: query
  })
}
// Danh sach tat ca cac bai viet
export function gProduct (query: any) {
  return request({
    url: '/gprdatcsssdf',
    method: 'get',
    params: query
  })
}
