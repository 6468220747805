/* All validations should be defined here */

export function isExternal (path: any) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * Validate a valid URL
 * @param {String} textval
 * @return {Boolean}
 */
export function validURL (url: any): boolean {
  const reg = /^[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/
  return reg.test(url)
}

/**
 * Validate a full-lowercase string
 * @return {Boolean}
 * @param {String} str
 */
export function validLowerCase (str: any): boolean {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/**
 * Validate a full-uppercase string
 * @return {Boolean}
 * @param {String} str
 */
export function validUpperCase (str: any): boolean {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * Check if a string contains only alphabet
 * @param {String} str
 * @param {Boolean}
 */
export function validAlphabets (str: any): boolean {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}
/**
 * Check if a string contains only alphabet
 * @param {String} str
 * @param {Boolean}
 */
export function validScript (str: any): boolean {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}
/**
 * Validate an email address
 * @param {String} email
 * @return {Boolean}
 */
export function validEmail (email: any): boolean {
  const re = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

/**
 * Validate an email address
 * @param {String} email
 * @return {Boolean}
 */
export function validPhone (vl: any): boolean {
  vl = vl.replace(/\s+/g, '')
  return (vl.length > 9 && vl.match(/^(\+?[0-9]\d{1,2}-?)?(\([0-9]\d{2}\)|[0-9]\d{2})-?[0-9]\d{2}-?\d{3,4}$/))
}
