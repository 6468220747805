<template>
  <Header />
  <Mainpage @sload="sload"/>
  <template v-if="loading">
  <Footer />
  </template>
</template>

<script>
import Header from '@/layouts/header/Header.vue' // @ is an alias to /src
import Mainpage from '@/layouts/main/section.vue' // @ is an alias to /src
import { defineAsyncComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'

export default {
  components: {
    Header,
    Mainpage,
    Footer: defineAsyncComponent(() =>
      import('@/layouts/footer/section.vue')
    )
  },
  setup () {
    const siteData = reactive({
      title: 'EBSC là Nền tảng Công nghệ số, chuyển đổi số toàn diện',
      description: 'EBSC là Nền tảng Công nghệ số, chuyển đổi số toàn diện tập trung vào Chiến lược, Hiệu suất với nền tảng tổng thể bằng sức mạnh của AI, BIG DATA, kết hợp các quy trình nghiệp vụ ...',
      locale: 'vi'
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      htmlAttrs: {
        lang: 'vi'
      },
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        },
        {
          property: 'og:locale:alternate',
          content: 'vi',
          key: 'vi'
        },
        {
          property: 'og:locale:alternate',
          content: 'en',
          key: 'en'
        }
      ],
      link: [
        {
          ref: 'canonical',
          href: window.location.href
        },
        {
          ref: 'alternate',
          hreflang: window.location.href
        }
      ]
    })
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    sload (vl) {
      this.loading = vl
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
