import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.code=='asdfsd23s')
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          class: "p-button-warning",
          label: _ctx.title,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.opencontact(_ctx.code)))
        }, null, 8, ["label"]))
      : (_ctx.code=='zcvsddge')
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 1,
            class: "p-button-warning",
            code: _ctx.code,
            label: _ctx.title,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.opencontact(_ctx.code)))
          }, null, 8, ["code", "label"]))
        : _createCommentVNode("", true)
  ]))
}