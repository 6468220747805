
import { Options, Vue } from 'vue-class-component'
import Button from 'primevue/button'
@Options({
  components: { Button },
  props: {
    code: {
      type: String,
      default: ''
    },
    tkcode: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      tkvl: ''
    }
  },
  created () {
    this.tkvl = this.tkcode
  },
  watch: {
    tkcode: function (newvl, oldvl) {
      this.tkvl = newvl
    }
  },
  methods: {
    opencontact (vl: any) {
      console.log('adf' + this.tkvl)
      this.$emit('utracking', { eid: this.tkvl })
      this.$emit('opencontact', { eid: this.tkvl })
    }
  }
})
export default class Block extends Vue {
  msg!: string
}
