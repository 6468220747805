<template>
  <div class="fixed right-0 hidden lg:inline-flex lg:w-7rem xl:w-10rem" style="top: 25%">
  <div class="right-sale-float">
  <div class="actbtn-item-holder lg:h-15rem">
    <div class="actbtn-item-getcall blink-image" data-toggle="modal" data-target="#callnowmodal">
    <i class="pi pi-tag"></i><br/><font class="lg:text-3xl xl:text-5xl">Khuyến Mãi 5%</font>
    </div>
  </div>
</div>
<dialog>
</dialog>
  </div>
</template>
<style>
.right-sale-float {
  border: 1px solid lightgray;
  border-radius: 5px;
  background-color: rgba(255,255,255,.7);
  left: 5px;
}
.actbtn-item-getcall i{
    font-size: 45px !important;
    line-height: 80px !important;
}
</style>
