<template>
    <div class="min-w-min">
          <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid" id="contact-form">
                  <div v-for="(fitem, findex) in lforms" :key="findex" class="field p-input-filled max-w-full">
                    <div v-if="(fitem.type==2||fitem.type==3)&&(fitem.valid==1)">
                      <div class="p-float-label">
                          <InputText :id="fitem.rlcode" v-model="v$.postForm[fitem.rlcode].$model" :class="{'p-invalid':v$.postForm[fitem.rlcode].$invalid && submitted}"  @focus="utracking(fitem.tkcode)"/>
                          <label :for="fitem.rlcode" :class="{'p-error':v$.postForm[fitem.rlcode].$invalid && submitted}" >{{ (lg ==='en' && fitem.titleen) ? fitem.titleen : fitem.title }}</label>
                      </div>
                      <small v-if="(v$.postForm[fitem.rlcode].$invalid && submitted) || v$.postForm[fitem.rlcode].$pending.$response" class="p-error">{{v$.postForm[fitem.rlcode].required.$message.replace('Value', fitem.title)}}</small>
                    </div>
                    <div v-if="(fitem.type==2||fitem.type==3)&&(fitem.valid==4)">
                      <div class="p-float-label">
                          <InputText :id="fitem.rlcode" v-model="v$.postForm[fitem.rlcode].$model" :class="{'p-invalid':v$.postForm[fitem.rlcode].$invalid && submitted}"  @focus="utracking(fitem.tkcode)"/>
                          <label :for="fitem.rlcode" :class="{'p-error':v$.postForm[fitem.rlcode].$invalid && submitted}" :messages="{ url: 'Cannot continue without filling a name' }">{{ (lg ==='en' && fitem.titleen) ? fitem.titleen : fitem.title }}</label>
                      </div>
                      <small v-if="(v$.postForm[fitem.rlcode].$invalid && submitted) || v$.postForm[fitem.rlcode].$pending.$response" class="p-error">{{ 'URL không đúng định dạng' }}</small>
                    </div>
                    <div v-if="(fitem.type==2||fitem.type==3)&&(fitem.valid==0)">
                    <div class="p-float-label">
                          <InputText :id="fitem.rlcode" v-model="postForm[fitem.rlcode]" @focus="utracking(fitem.tkcode)"/>
                          <label :for="fitem.rlcode" >{{ (lg === 'en' && fitem.titleen) ? fitem.titleen : fitem.title }}</label>
                    </div>
                  </div>
                  <div v-if="(fitem.type==2||fitem.type==3)&&(fitem.valid==2)">
                      <div class="p-float-label p-input-icon-right">
                          <i class="pi pi-envelope" />
                          <InputText :id="postForm[fitem.rlcode]" v-model="v$.postForm[fitem.rlcode].$model" :class="{'p-invalid':v$.postForm[fitem.rlcode].$invalid && submitted}" aria-describedby="email-error"/>
                          <label :for="postForm[fitem.rlcode]" :class="{'p-error':v$.postForm[fitem.rlcode].$invalid && submitted}">Email*</label>
                      </div>
                      <span v-if="v$.postForm[fitem.rlcode].$error && submitted">
                      <span id="email-error" v-for="(error, index) of v$.postForm[fitem.rlcode].$errors" :key="index">
                      <small class="p-error">{{error.$message}}</small>
                      </span>
                      </span>
                      <small v-else-if="(v$.postForm[fitem.rlcode].$invalid && submitted) || v$.postForm[fitem.rlcode].$pending.$response" class="p-error">{{v$.postForm[fitem.rlcode].required.$message.replace('Value', 'Email')}}</small>
                  </div>
                  <div v-if="(fitem.type==2||fitem.type==3)&&(fitem.valid==3)">
                      <div class="p-float-label">
                          <InputText :id="fitem.rlcode" v-model="v$.postForm[fitem.rlcode].$model" :class="{'p-invalid':v$.postForm[fitem.rlcode].$invalid && submitted}"  @focus="utracking(fitem.tkcode)"/>
                          <label :for="fitem.rlcode" :class="{'p-error':v$.postForm[fitem.rlcode].$invalid && submitted}">{{ (lg ==='en' && fitem.titleen) ? fitem.titleen : fitem.title }}</label>
                      </div>
                      <small v-if="(v$.postForm[fitem.rlcode].$invalid && submitted) || v$.postForm[fitem.rlcode].$pending.$response" class="p-error">{{v$.postForm[fitem.rlcode].required.$message.replace('Value', fitem.title)}}</small>
                  </div>
                  <div v-if="(fitem.type==6||fitem.type==7)&&(fitem.valid==0)">
                      <MultiSelect v-model="postForm[fitem.rlcode]" :options="fitem.options" optionLabel="title" optionValue="code" :placeholder="(lg ==='en' && fitem.titleen) ? fitem.titleen : fitem.title" @focus="utracking(fitem.tkcode)"/>
                  </div>
                  <div v-if="(fitem.type==14)&&(fitem.valid==1)">
                  <div class="field-checkbox">
                    <Checkbox :id="postForm[fitem.rlcode]" value="Accept" :name="postForm[fitem.rlcode]" v-model="v$.postForm[fitem.rlcode].$model" :class="{'p-invalid':v$.postForm[fitem.rlcode].$invalid && submitted}" />
                    <label :for="postForm[fitem.rlcode]" :class="{'p-error': v$.postForm[fitem.rlcode].$invalid && submitted}">{{ (lg ==='en' && fitem.titleen) ? fitem.titleen : fitem.title }}</label>
                  </div>
                  </div>
                  <div v-if="fitem.type==15">
                    <Button type="submit" :loading="loading" :label="(lg ==='en' && fitem.titleen) ? fitem.titleen : fitem.title" class="mt-2" @click="utracking(fitem.tkcode)"/>
                  </div>
              </div>
            </form>
    </div>
</template>

<script>
import { email, required, helpers as thelpers } from '@vuelidate/validators'
import { helpers, url } from 'vuelidate/lib/validators'
import { useVuelidate } from '@vuelidate/core'
import InputText from 'primevue/inputtext'
import Checkbox from 'primevue/checkbox'
import Button from 'primevue/button'
import MultiSelect from 'primevue/multiselect'
import { gfDatasource, sDatasource, lDatasource } from '@/api/datasource'
const { withAsync } = helpers
const scriptbanned = (value) => !helpers.req(value) || !value.includes('script')
const vldphone = helpers.regex('mob', /^(\+?[0-9]\d{1,2}-?)?(\([0-9]\d{2}\)|[0-9]\d{2})-?[0-9]\d{2}-?\d{3,4}$/)
const vldurl = helpers.regex('mob', /^[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/)
const validator = () => true
export default {
  components: { InputText, Checkbox, Button, MultiSelect },
  props: {
    fcode: { type: String, default: '' },
    gemail: { type: String, default: '' }
  },
  setup: () => ({
    v$: useVuelidate()
  }),
  data () {
    return {
      loading: false,
      postForm: {
        name: '',
        email: '',
        congty: '',
        chucvu: '',
        phone: '',
        website: '',
        nuser: '',
        iwant: [],
        lpdvl: []
      },
      name: '',
      email: '',
      congty: '',
      chucvu: '',
      phone: '',
      website: '',
      nuser: '',
      iwant: '',
      lpdvl: [],
      lpds: [],
      liwants: [],
      date: null,
      accept: null,
      submitted: false,
      showMessage: false,
      lforms: [],
      lg: 'vi',
      lQuery: {
        pageid: '',
        pagetitle: ''
      }
    }
  },
  countryService: null,
  validations () {
    const localRules = {
      postForm: {}
    }
    if (this.lforms) {
      this.lforms.forEach((it) => {
        if (it.valid === 1) {
          localRules.postForm[it.rlcode] = { required, scriptbanned }
        } else if (it.valid === 2) {
          localRules.postForm[it.rlcode] = { email, required, scriptbanned }
        } else if (it.valid === 3) {
          localRules.postForm[it.rlcode] = { required, vldphone }
        } else if (it.valid === 4) {
          localRules.postForm[it.rlcode] = { vldurl, scriptbanned }
        } else {
          localRules.postForm[it.rlcode] = { scriptbanned }
        }
      })
    }
    return localRules
  },
  watch: {
    fcode: function (newvl, oldvl) {
      this.gLform(newvl)
    }
  },
  created () {
    this.lg = sessionStorage.getItem('st-lg')
    this.gListPD()
    this.lQuery = this.psend
    this.email = this.gemail
  },
  methods: {
    async gListPD () {
      const sendData = { lg: this.lg }
      await this.$store.dispatch('product/l', sendData).then(async (res) => {
        if (res && res.data.data) {
          this.lpds = res.data.data.data
        }
        this.gltoimuons()
      })
    },
    gLForm (vl) {
      return new Promise((resolve, reject) => {
        gfDatasource({ lform: vl }).then(async (res) => {
          if (res && res.data.rdata) {
            var tmpdata = []
            var tmppost = {}
            var vm = this
            res.data.rdata.forEach((it) => {
              var loptions = []
              if (it.ldata.dts === 'iwant') {
                loptions = vm.liwants
              }
              if (it.ldata.dts === 'products') {
                loptions = vm.lpds
              }
              tmpdata.push({ type: it.ldata.type, valid: parseInt(it.ldata.valid), title: it.ldata.title, titleen: it.ldata.titleen, rlcode: it.ldata.rlcode, tkcode: it.ldata.tkcode, options: loptions })
              if (it.ldata.type === 14) {
                tmppost[it.ldata.rlcode] = 'Accept'
              } else {
                if (it.ldata.rlcode === 'email') {
                  tmppost[it.ldata.rlcode] = vm.gemail
                } else {
                  tmppost[it.ldata.rlcode] = ''
                }
              }
            })
            this.lforms = tmpdata
            this.postForm = tmppost
            resolve(this.lforms)
          }
        })
      })
    },
    handleSubmit (isFormValid) {
      this.submitted = true
      this.loading = true
      if (!isFormValid) {
        this.loading = false
        return
      }
      sDatasource({ code: 'customercontact', data: this.postForm }).then((res) => {
        this.loading = false
        if (res) {
          this.$emit('senddone', this.postForm)
        }
      })
    },
    resetForm () {
      this.name = ''
      this.email = ''
      this.phone = ''
      this.date = null
      this.country = null
      this.accept = null
      this.submitted = false
    },
    utracking (vl) {
      this.$emit('utracking', vl)
    },
    gltoimuons () {
      lDatasource({ code: 'iwant' }).then((res) => {
        if (res) {
          var tmpvl = []
          res.data.rdata.forEach((item) => {
            var title = item.ldata.title
            if (this.lg === 'en' && item.ldata.titleen) {
              title = item.ldata.titleen
            }
            tmpvl.push({ code: item.code, title: title })
          })
          this.liwants = tmpvl
        }
        this.gLForm(this.fcode)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.form-demo {
  .card {
    min-width: 450px
    form {
      margin-top: 2rem
    }
    .field {
      margin-bottom: 1.5rem
    }
  }
  @media screen and (max-width: 960px) {
    .card {
      width: 80%
    }
  }
}
</style>
