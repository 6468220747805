<template>
    <div class="min-w-min">
        <div v-if="showMessage">
            <div class="flex align-items-center flex-column pt-6 px-3">
                <i class="pi pi-check-circle" :style="{fontSize: '5rem', color: 'var(--green-500)' }"></i>
                <h3>Bạn đã gửi liên hệ thành công!</h3>
                <h4>Chúng tôi sẽ liên hệ lại trong vòng 24h</h4>
                <p :style="{lineHeight: 1.5, textIndent: '1rem'}">
                  Vui lòng chia sẻ với bạn bè để nhận chiết khấu cho lần đặt hàng tiếp theo.
                </p>
            </div>
            <SendSharingF @Sendsharing="Sendsharing" :sender="sender" />
        </div>
        <div class="grid" v-if="!showMessage">
            <div class="hidden md:inline-flex md:col-6 lg:col-6 xl:col-6 bg-green-400">
              <div class="row">
                <!--begin col-md-12 -->
                <div class="col-md-12 text-center">
                <h3 class="section-title">{{gsupport.title}}</h3>
                </div>
                <!--end col-md-12 -->
                <!--begin col-md-12 -->
                <div class="col-md-12" v-html="gsupport.desc">
                </div>
            <!--end col-md-12 -->
            <div class="col-md-12 text-center mt-8 box-border-dot">
                <h2>{{gsupport.titleht}}</h2>
                <h4 class="mb-2">{{gsupport.titlephone}}</h4>
                <h3 class="text-danger"> {{gsupport.phone}}</h3>
                <h4 class="mb-2">{{gsupport.titleemail}}</h4>
                <h3>{{gsupport.email}}</h3>
            </div>
            </div>
            </div>
            <div class="col-12 md:col-6 lg:col-6 xl:col-6 max-w-screen">
               <Cform :fcode="'a123'" @utracking="utracking" @senddone="senddone" :gemail="esend" />
            </div>
        </div>
    </div>
</template>

<script>
import { email, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Cform from '@/components/cform'
import SendSharingF from '@/components/sendsharing'
import { ctntrack } from '@/api/tracking'
import { lDatasource, sDatasource, gtDatasource } from '@/api/datasource'
export default {
  components: { Cform, SendSharingF },
  props: {
    psend: { type: String, default: '' },
    esend: { type: String, default: '' }
  },
  setup: () => ({
    v$: useVuelidate()
  }),
  data () {
    return {
      postForm: {},
      lpdvl: [],
      lpds: [],
      liwants: [],
      sender: {},
      gsupport: { title: '', desc: '', titleht: '', titlephone: '', phone: '', titleemail: '', email: '' },
      date: null,
      accept: null,
      submitted: false,
      showMessage: 0,
      lQuery: {
        pageid: '',
        pagetitle: '',
        lg: 'vi'
      }
    }
  },
  countryService: null,
  watch: {
    psend: function (newvl, oldvl) {
      this.lQuery.pageid = newvl.pageid
      this.lQuery.pagetitle = newvl.pagetitle
    },
    esend: function (newvl, oldvl) {
      this.email = newvl
    }
  },
  created () {
    this.lQuery = this.psend
    this.email = this.esend
    this.lQuery.lg = sessionStorage.getItem('st-lg')
    this.gtdata()
  },
  methods: {
    handleSubmit (isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      this.postForm = {}
      sDatasource({ code: 'customercontact', data: this.postForm }).then((res) => {
        this.showMessage = true
      })
    },
    senddone (vl) {
      this.showMessage = true
      this.sender = vl
      sessionStorage.setItem('st-name', vl.name)
      sessionStorage.setItem('st-email', vl.email)
      this.close(3)
    },
    gtdata () {
      this.lQuery.code = 'thongtinlienhe'
      lDatasource(this.lQuery).then((res) => {
        if (res) {
          var tmpvl = {}
          res.data.rdata.forEach((item) => {
            tmpvl = item.ldata
          })
          this.gsupport = tmpvl
        }
      })
    },
    Sendsharing () {
      this.showMessage = false
    },
    close (vl) {
      this.$emit('close', vl)
    },
    utracking (vl) {
      var gss = sessionStorage.getItem('st-acpage')
      var gtdata = { rsession: gss, pageid: this.lQuery.pageid, pagetitle: this.lQuery.pagetitle, elementid: vl }
      ctntrack(gtdata)
    }
  }
}
</script>
<style lang="scss" scoped>
.form-demo {
  .card {
    min-width: 450px
    form {
      margin-top: 2rem
    }
    .field {
      margin-bottom: 1.5rem
    }
  }
  @media screen and (max-width: 960px) {
    .card {
      width: 80%
    }
  }
}
</style>
