import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "grid grid-nogutter" }
const _hoisted_3 = {
  class: "col-12",
  style: {"text-align":"right"}
}
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "product-list-item" }
const _hoisted_6 = { class: "product-list-detail" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "product-name" }
const _hoisted_9 = { class: "m-1" }
const _hoisted_10 = { class: "product-name" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "product-list-action" }
const _hoisted_13 = ["src", "alt"]
const _hoisted_14 = { class: "hidden md:block" }
const _hoisted_15 = { class: "col-12 sm:col-4" }
const _hoisted_16 = { class: "product-grid-item card" }
const _hoisted_17 = { class: "product-grid-item-content" }
const _hoisted_18 = { class: "product-name" }
const _hoisted_19 = { class: "product-name" }
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = ["src", "title", "alt"]
const _hoisted_22 = { class: "hidden md:block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DataViewLayoutOptions = _resolveComponent("DataViewLayoutOptions")!
  const _component_Rating = _resolveComponent("Rating")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataView = _resolveComponent("DataView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DataView, {
      value: _ctx.lpds,
      layout: _ctx.layout,
      paginator: true,
      rows: _ctx.setrow,
      sortOrder: _ctx.sortOrder,
      sortField: _ctx.sortField
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_DataViewLayoutOptions, {
              modelValue: _ctx.layout,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.layout) = $event))
            }, null, 8, ["modelValue"])
          ])
        ])
      ]),
      list: _withCtx((slotProps) => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("a", {
                class: "no-underline",
                href: '/san-pham/'+slotProps.data.slug + '-' + slotProps.data.code
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("h2", _hoisted_9, _toDisplayString(slotProps.data.shorttitle), 1)
                ]),
                _createElementVNode("div", _hoisted_10, _toDisplayString(slotProps.data.title), 1)
              ], 8, _hoisted_7),
              _createElementVNode("div", {
                class: "product-description p-2",
                innerHTML: slotProps.data.description
              }, null, 8, _hoisted_11)
            ]),
            _createElementVNode("div", _hoisted_12, [
              (slotProps.data.qrcode)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "p-1 flex align-items-center justify-content-center",
                    src: _ctx.url+'/qrcode/' + slotProps.data.qrcode,
                    alt: slotProps.data.title
                  }, null, 8, _hoisted_13))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_Rating, {
                  value: _ctx.ratingvl,
                  readonly: true,
                  cancel: false
                }, null, 8, ["value"])
              ]),
              _createVNode(_component_Button, {
                class: "p-button-warning",
                icon: "pi pi-shopping-cart",
                label: _ctx.t('sproduct.dangky'),
                onClick: ($event: any) => (_ctx.openshopcart(slotProps.data.code)),
                iconPos: _ctx.iconPos
              }, null, 8, ["label", "onClick", "iconPos"])
            ])
          ])
        ])
      ]),
      grid: _withCtx((slotProps) => [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("h2", null, _toDisplayString(slotProps.data.shorttitle), 1)
              ]),
              _createElementVNode("div", _hoisted_19, _toDisplayString(slotProps.data.title), 1),
              _createElementVNode("div", {
                class: "product-description",
                innerHTML: slotProps.data.description
              }, null, 8, _hoisted_20),
              _createElementVNode("div", null, [
                _createElementVNode("img", {
                  src: _ctx.url+'/qrcode/'+slotProps.data.qrcode,
                  title: slotProps.data.title,
                  alt: slotProps.data.title
                }, null, 8, _hoisted_21)
              ]),
              _createElementVNode("div", _hoisted_22, [
                _createVNode(_component_Rating, {
                  value: _ctx.ratingvl,
                  readonly: true,
                  cancel: false
                }, null, 8, ["value"])
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_Button, {
                  icon: "pi pi-shopping-cart",
                  label: _ctx.t('sproduct.dangky'),
                  onClick: ($event: any) => (_ctx.openshopcart(slotProps.data.code))
                }, null, 8, ["label", "onClick"])
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["value", "layout", "rows", "sortOrder", "sortField"])
  ]))
}