<template>
  <div class="fixed bottom-0 right-0">
  <div class="chat-float">
  <div class="actbtn-item-holder">
    <div class="actbtn-item-getcall" @click="opDl">
    <i class="pi pi-comments"></i>
    </div>
  </div>
  </div>
<Dialog header="EBSC AI Consulting" v-model:visible="sct" :breakpoints="{ '1124px': '25vw', '1024px': '50vw', '960px': '75vw', '640px': '100vw' }" :style="{ width: '25vw', height: '550px' }" position="bottomright" :modal="true">
    <div class="grid">
    <div class="col-12">
      <ChatbotFloat />
    </div>
    </div>
</Dialog>
</div>
</template>
<script>
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Divider from 'primevue/divider'
import ChatbotFloat from './chatbotfloat'
import { Options, Vue } from 'vue-class-component'
import { ctntrack } from '@/api/tracking'
@Options({
  components: {
    Dialog, Button, InputText, Divider, ChatbotFloat
  },
  data () {
    return {
      sct: false,
      lpages: [{ code: 'home', slug: '' }, { code: 'congty', slug: 'cong-ty' }]
    }
  },
  created () {
    var gpath = this.$route.path.split('/')
  },
  methods: {
    opDl () {
      this.sct = true
      this.$emit('stopshowsurvey')
      this.utracking('btncn')
    },
    utracking (vl) {
      var gtdata = { eid: vl }
      this.$emit('utracking', gtdata)
    }
  }
})
export default class Leftcontact extends Vue {}
</script>
<style>
.actbtn-holder-float {
  width: 85px;
  height: 85px;
  border: 1px solid lightgray;
  border-radius: 5px;
  background-color: rgba(255,255,255,.7);
  left: 5px;
}
.actbtn-item-getcall {
    font-size: 45px !important;
    color: green;
}
.actbtn-item-getcall i{
    font-size: 45px !important;
    line-height: 80px !important;
}
.actbtn-item-getcall:hover{
  cursor:pointer;
}
/* Firefox old*/
@-moz-keyframes blinkimg {
    0% {
        color:red;
    }
    50% {
        color:yellow;
    }
    100% {
        color:red;
    }
}
@-webkit-keyframes blinkimg {
    0% {
        color:red;
    }
    50% {
        color:yellow;
    }
    100% {
        color:red;
    }
}
/* IE */
@-ms-keyframes blinkimg {
  0% {
        color:red;
    }
    50% {
        color:yellow;
    }
    100% {
        color:red;
    }
}
/* Opera and prob css3 final iteration */
@keyframes  blinkimg {
    0% {
        color:red;
    }
    50% {
        color:yellow;
    }
    100% {
        color:red;
    }
}
.blink-image {
    -moz-animation: blinkimg normal 2s infinite; /* Firefox */
    -webkit-animation: blinkimg normal 2s infinite; /* Webkit */
    -ms-animation: blinkimg normal 2s infinite; /* IE */
    animation: blinkimg normal 2s infinite; /* Opera and prob css3 final iteration */
}
</style>
<style>
.chat-float {
  width: 80px;
  height: 80px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: rgba(255,255,255,.7);
  left: 5px;
}
.actbtn-item-getcall {
    font-size: 45px !important;
    color: green;
}
</style>
