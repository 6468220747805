
import { ref, reactive, defineComponent } from 'vue'
import Button from 'primevue/button'
import { mapGetters } from 'vuex'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  components: { Button },
  setup () {
    const { t } = useI18n()
    return { t }
  },
  props: {
    page: {
      type: String,
      default: 'home'
    },
    code: {
      type: String,
      default: ''
    },
    tkcode: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      lpds: [],
      layout: 'list',
      sortKey: null,
      sortOrder: null,
      sortField: null,
      sortOptions: [
        { label: 'Price High to Low', value: '!price' },
        { label: 'Price Low to High', value: 'price' }
      ],
      ratingvl: 5
    }
  },
  computed: {
    ...mapGetters({
      ProductList: 'ProductList'
    })
  },
  created () {
    this.gList()
  },
  mounted () {
    if (this.$route.hash && this.$route.hash === '#price') {
      this.scrollToElement()
    }
  },
  methods: {
    async gList () {
      const sendData = {}
      await this.$store.dispatch('product/l', sendData).then(async (res: any) => {
        if (res) {
          this.lpds = res.data.data
        }
      })
    },
    scrollToElement () {
      const el: any = this.$refs.scrollToMe
      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    },
    openshopcart (vl: any) {
      var dtvl = { stype: 1, nuser: vl }
      console.log(this.tkcode)
      this.$emit('utracking', { eid: this.tkcode })
      this.$emit('openshopcart', vl)
    }
  }
})
