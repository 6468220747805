<template>
  <div>
  <template v-if="code=='fgemaasdf'">
  <form @submit.prevent="ghsm(!v$.$invalid)" class="p-fluid" id="contact-form">
    <Toast position="top-center" class="bg-orange-200"/>
    <div class="p-inputgroup">
      <InputText @focus="utracking('he1l')" :placeholder="t('sform.vlnhapemail')" id="email" v-model="v$.email.$model" :class="{'p-invalid':v$.email.$invalid && submitted}" aria-describedby="email-error"/>
      <Button icon="pi pi-send" class="p-button-warning" @click="utracking('heb1l')" :label="title" type="submit"/>
    </div>
  </form>
  </template>
  <template v-else-if="code=='fgctfsdfsd'">
  <div class="card">
    <div class="flex align-items-center justify-content-center font-bold m-2" >{{ title }}</div>
    <div class="flex align-items-center justify-content-center">
    <InputText :placeholder="t('sform.hovaten')" class="w-full m-3"  @click="opencontact(tkcode)" aria-label="Họ và tên"/>
    </div>
    <div class="flex align-items-center justify-content-center">
    <InputText placeholder="Email"  class="w-full m-3"  @click="opencontact(tkcode)" aria-label="Email"/>
    </div>
    <div class="flex align-items-center justify-content-center" >
    <InputText :placeholder="t('sform.dienthoai')" class="w-full m-3"   @click="opencontact(tkcode)" />
    </div>
    <div class="flex align-items-center justify-content-center">
    <InputText :placeholder="t('sform.congty')" class="w-full m-3"   @click="opencontact(tkcode)" />
    </div>
    <div class="flex align-items-center justify-content-center">
    <Button class="w-full m-3 p-button-warning" :label="t('sform.toicantuvan')"   @click="opencontact(tkcode)" />
    </div>
</div>
  </template>
  <template v-else-if="code=='ssdfsdfasd'">
  <div>
  {{t('sform.toimuon')}}: <SplitButton :model="lTMs" :label="lTMs[0].label" style="width:320px;" class="p-button-warning"  @click="opencontact(tkcode)"/>
  </div>
  </template>
  </div>
</template>

<script>
import { Options, Vue } from 'vue-class-component'
import { ref, reactive, defineComponent } from 'vue'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import SplitButton from 'primevue/splitbutton'
import { email, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import InlineMessage from 'primevue/inlinemessage'
import { sclmDatasource, lDatasource } from '@/api/datasource'
import { useToast } from 'primevue/usetoast'
import Toast from 'primevue/toast'
import { useI18n } from 'vue-i18n'
export default defineComponent({
  components: { Toast, InputText, Button, SplitButton },
  props: {
    code: {
      type: String,
      default: ''
    },
    tkcode: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  setup () {
    const state = reactive({
      email: ''
    })
    const rules = {
      email: { required, email }
    }
    const toast = useToast()
    const submitted = ref(false)
    const showMessage = ref(false)
    const v$ = useVuelidate(rules, state)
    const handleSubmit = (isFormValid) => {
      submitted.value = true
      if (!isFormValid) {
        toast.add({ severity: 'warning', summary: 'Thông báo', detail: 'Địa chỉ email không chính xác!', life: 3000 })
        return false
      }
      toggleDialog()
      return true
    }
    const toggleDialog = () => {
      showMessage.value = !showMessage.value
      if (!showMessage.value) {
        resetForm()
      }
    }
    const resetForm = () => {
      state.email = ''
      submitted.value = false
    }
    const { t } = useI18n()
    return { state, v$, handleSubmit, toggleDialog, submitted, showMessage, t }
  },
  data () {
    return {
      selectedTM: 'baogia',
      lTMs: [
        { label: 'Báo giá phần mềm EBSC', code: 'baogia', command: () => { this.opencontact(this.tkcode) } },
        { label: 'Đào tạo BSC/KPI', code: 'daotao', command: () => { this.opencontact(this.tkcode) } },
        { label: 'Tư vấn chiến lược, hiệu suất', code: 'tuvan', command: () => { this.opencontact(this.tkcode) } },
        { label: 'Dùng thử', code: 'dungthu', command: () => { this.opencontact(this.tkcode) } },
        { label: 'Hỗ trợ khách hàng', code: 'hotro', command: () => { this.opencontact(this.tkcode) } },
        { label: 'Hợp tác', code: 'hoptac', command: () => { this.opencontact(this.tkcode) } },
        { label: 'Liên hệ đầu tư', code: 'dautu', command: () => { this.opencontact(this.tkcode) } }
      ],
      lQuery: {
        code: 'iwant',
        lg: 'vi'
      }
    }
  },
  created () {
    this.lQuery.lg = sessionStorage.getItem('st-lg')
    this.gtdata()
  },
  methods: {
    utracking (vl) {
      this.$emit('utracking', { eid: vl })
    },
    opencontact (vl) {
      this.$emit('utracking', { eid: vl })
      var dvl = { eid: vl }
      this.$emit('opencontact', dvl)
    },
    gtdata () {
      lDatasource({ code: 'iwant' }).then((res) => {
        if (res) {
          var tmpvl = []
          var vm = this
          res.data.rdata.forEach((item) => {
            var title = item.ldata.title
            if (this.lQuery.lg === 'en') {
              title = item.ldata.titleen
            }
            var tmpvl1 = { label: title, code: item.code, command: () => { vm.opencontact(vm.tkcode) } }
            tmpvl.push(tmpvl1)
          })
          this.lTMs = tmpvl
        }
      })
    },
    ghsm (vl) {
      if (this.handleSubmit(vl)) {
        sclmDatasource(this.state).then((res) => {
          var dvl = { eid: this.tkcode, email: this.state.email }
          this.$emit('opencontact', dvl)
        })
      }
    }
  }
})

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.p-splitbutton .p-button{
  background-color: #5f2800 !important;
  color: white !important;
  border: none !important
}
</style>
