import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "p-inputgroup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Toast, {
      position: "top-center",
      class: "bg-orange-200"
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lmails, (lmi, lmindex) => {
      return (_openBlock(), _createElementBlock("div", {
        key: lmindex,
        class: "flex justify-content-center mt-2"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_InputText, {
            modelValue: lmi.m,
            "onUpdate:modelValue": ($event: any) => ((lmi.m) = $event),
            placeholder: "vd: info@proview.vn"
          }, null, 8, ["modelValue", "onUpdate:modelValue"]),
          _createVNode(_component_Button, {
            onClick: _ctx.addEmail,
            icon: "pi pi-plus",
            class: "p-button-warning"
          }, null, 8, ["onClick"])
        ])
      ]))
    }), 128)),
    _createElementVNode("div", null, [
      _createVNode(_component_Button, {
        label: "Chia sẻ",
        onClick: _ctx.Sendsharing,
        icon: "pi pi-share-alt",
        class: "mt-2 w-full p-button-warning"
      }, null, 8, ["onClick"])
    ])
  ]))
}