<template>
    <div class="w-full">
        <div v-if="showMessage">
            <div class="flex align-items-center flex-column pt-6 px-3">
                <i class="pi pi-check-circle" :style="{fontSize: '5rem', color: 'var(--green-500)' }"></i>
                <h3>Bạn đã gửi liên hệ thành công!</h3>
                <h4>Chúng tôi sẽ liên hệ lại trong vòng 24h</h4>
                <p :style="{lineHeight: 1.5, textIndent: '1rem'}">
                  Vui lòng chia sẻ với bạn bè để nhận chiết khấu cho lần đặt hàng tiếp theo.
                </p>
            </div>
            <div class="flex justify-content-center">
                <Button label="Chia sẻ" @click="toggleDialog" class="p-button-text" />
            </div>
        </div>
        <div class="grid" v-if="!showMessage">
            <div class="col-12 max-w-screen">
                <form @submit.prevent="handleSubmit(!v$.$invalid)" class="grid" id="contact-form">
                    <div class="col-12 mt-4">
                        <div class="p-float-label p-input-filled">
                            <InputText id="name" v-model="v$.name.$model" :class="{'p-invalid':v$.name.$invalid && submitted}" class="w-full" />
                            <label for="name" :class="{'p-error':v$.name.$invalid && submitted}">Họ tên*</label>
                        </div>
                        <small v-if="(v$.name.$invalid && submitted) || v$.name.$pending.$response" class="p-error">{{v$.name.required.$message.replace('Value', 'Name')}}</small>
                    </div>
                    <div class="col-6">
                        <div class="p-float-label p-input-filled p-input-icon-right w-full">
                            <i class="pi pi-envelope" />
                            <InputText id="email" class="w-full" v-model="v$.email.$model" :class="{'p-invalid':v$.email.$invalid && submitted}" aria-describedby="email-error"/>
                            <label for="email" :class="{'p-error':v$.email.$invalid && submitted}">Email*</label>
                        </div>
                        <span v-if="v$.email.$error && submitted">
                            <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                            <small class="p-error">{{error.$message}}</small>
                            </span>
                        </span>
                        <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">{{v$.email.required.$message.replace('Value', 'Email')}}</small>
                    </div>
                    <div class="col-6">
                        <div class="p-float-label p-input-filled">
                            <InputText id="phone" v-model="v$.phone.$model" :class="{'p-invalid':v$.phone.$invalid && submitted}" class="w-full" />
                            <label for="phone" :class="{'p-error':v$.phone.$invalid && submitted}">Số điện thoại*</label>
                        </div>
                        <small v-if="(v$.phone.$invalid && submitted) || v$.phone.$pending.$response" class="p-error">{{v$.phone.required.$message.replace('Value', 'Số điện thoại')}}</small>
                    </div>
                    <div class="col-12">
                        <div class="p-float-label p-input-filled">
                            <InputText id="congty" v-model="congty" class="w-full" />
                            <label for="congty" >Công ty</label>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="p-float-label p-input-filled">
                            <InputText id="chucvu" v-model="chucvu" class="w-full"  />
                            <label for="chucvu">Chức vụ</label>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="p-float-label p-input-filled">
                            <InputText id="website" v-model="website" class="w-full" />
                            <label for="website">Website</label>
                        </div>
                    </div>
                    <div class="col-12">
                      <MultiSelect v-model="iwant" :options="liwants" optionLabel="title" optionValue="code" placeholder="Tôi muốn" class="w-full p-input-filled"/>
                    </div>
                    <div class="col-12">
                      <div class="line-height-4">Thông tin thêm</div>
                      <Textarea v-model="ndkhac" rows="5" cols="30" class="w-full p-input-filled">
                      </Textarea>
                    </div>
                    <div class="col-12 w-full">
                    <Button @click="previousbtn" class="p-button-secondary left-0" icon="pi pi-arrow-left" label="Trang trước"/>
                    <Button type="submit" :loading="loading" class="p-button-warning ml-4" label="Xác nhận" icon="pi pi-arrow-right" iconPos="right" />
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { email, required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import Button from 'primevue/button'
import MultiSelect from 'primevue/multiselect'
import { lDatasource, scDatasource } from '@/api/datasource'
import { useToast } from 'primevue/usetoast'
import Toast from 'primevue/toast'
export default {
  components: { InputText, Textarea, Button, MultiSelect },
  props: {
    psend: { type: String, default: '' },
    gdata: { type: Object },
    esend: { type: String, default: '' }
  },
  setup: () => ({
    v$: useVuelidate()
  }),
  data () {
    return {
      loading: false,
      postForm: {
        name: '',
        email: '',
        congty: '',
        chucvu: '',
        phone: '',
        website: '',
        iwant: [],
        ndkhac: '',
        lpds: {}
      },
      name: '',
      email: '',
      congty: '',
      chucvu: '',
      phone: '',
      website: '',
      iwant: '',
      lpdvl: [],
      lpds: [],
      ndkhac: '',
      liwants: [],
      date: null,
      accept: null,
      submitted: false,
      showMessage: false
    }
  },
  countryService: null,
  validations () {
    return {
      name: {
        required
      },
      email: {
        required,
        email
      },
      phone: {
        required
      }
    }
  },
  created () {
    this.gListPD()
    this.gltoimuons()
  },
  mounted () {
    this.postForm.lpds = this.gdata
  },
  methods: {
    async gListPD () {
      this.postForm.lpds = this.gdata
      var tmpemail = sessionStorage.getItem('st-email')
      if (tmpemail) {
        this.email = tmpemail
      }
    },
    handleSubmit (isFormValid) {
      this.loading = true
      this.submitted = true
      this.postForm = {
        name: this.name,
        email: this.email,
        congty: this.congty,
        chucvu: this.chucvu,
        phone: this.phone,
        website: this.website,
        iwant: this.iwant,
        lpds: this.gdata
      }
      var vl = { code: 2, data: this.postForm }
      if (!isFormValid) {
        this.loading = false
        this.$emit('jumpnext', vl)
        return
      }
      var psend = !this.psend ? this.psend : 0
      var esend = !this.esend ? this.esend : 0
      scDatasource({ code: 'customercontact', psend: psend + '-' + esend, data: this.postForm }).then((res) => {
        vl.code = 1
        vl.data.dhcode = res.data
        this.$emit('jumpnext', vl)
        this.loading = false
      })
    },
    previousbtn () {
      var vl = { code: 0 }
      this.$emit('jumpnext', vl)
    },
    resetForm () {
      this.name = ''
      this.email = ''
      this.phone = ''
      this.date = null
      this.country = null
      this.accept = null
      this.submitted = false
    },
    gltoimuons () {
      lDatasource({ code: 'iwant' }).then((res) => {
        if (res) {
          var tmpvl = []
          res.data.rdata.forEach((item) => {
            tmpvl.push({ code: item.code, title: item.ldata.title })
          })
          this.liwants = tmpvl
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.form-demo {
  .card {
    min-width: 450px
    form {
      margin-top: 2rem
    }
    .field {
      margin-bottom: 1.5rem
    }
  }
  @media screen and (max-width: 960px) {
    .card {
      width: 80%
    }
  }
}
</style>
