
import { ref, reactive, defineComponent } from 'vue'
import Button from 'primevue/button'
import DataView from 'primevue/dataview'
import Rating from 'primevue/rating'
import { lProduct } from '@/api/product'
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import { useI18n } from 'vue-i18n'
import { mapGetters } from 'vuex'
export default defineComponent({
  components: { Button, DataView, DataViewLayoutOptions, Rating },
  props: {
    code: {
      type: String,
      default: ''
    },
    pageid: {
      type: String,
      default: ''
    },
    tkcode: {
      type: String,
      default: ''
    },
    slug: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    srow: {
      type: Number,
      default: 6
    }
  },
  setup () {
    const { t } = useI18n()
    return { t }
  },
  data () {
    return {
      lpds: [],
      layout: 'list',
      setrow: 6,
      url: '',
      gslug: '',
      tkvl: '',
      iconPos: 'left',
      sortKey: null,
      sortOrder: -1,
      sortField: null,
      sortOptions: [
        { label: 'Price High to Low', value: '!price' },
        { label: 'Price Low to High', value: 'price' }
      ],
      ratingvl: 5
    }
  },
  computed: {
    ...mapGetters({
      ProductList: 'ProductList'
    })
  },
  created () {
    this.tkvl = this.tkcode
    this.gList()
  },
  watch: {
    slug: function (nvl, oldvl) {
      return nvl
    },
    tkcode: function (nvl, oldvl) {
      return nvl
    }
  },
  mounted () {
    this.tkvl = this.tkcode
  },
  methods: {
    async gList () {
      var lg = sessionStorage.getItem('st-lg')
      const sendData = { slug: this.slug, lg: lg }
      if (document.documentElement.offsetWidth < 540) {
        this.iconPos = 'top'
      }
      await lProduct(sendData).then(async (res: any) => {
        if (res) {
          this.lpds = res.data.data.data
          this.url = res.data.url
        }
      })
    },
    onSortChange (event: any) {
      const value = event.value.value
      const sortValue = event.value
      if (value.indexOf('!') === 0) {
        this.sortOrder = -1
        this.sortField = value.substring(1, value.length)
        this.sortKey = sortValue
      } else {
        this.sortOrder = 1
        this.sortField = value
        this.sortKey = sortValue
      }
    },
    openshopcart (vl: any) {
      var dtvl = { pageid: this.pageid, pdcode: vl, stype: 0, nuser: 10 }
      this.$emit('utracking', { eid: this.tkvl })
      this.$emit('openshopcart', dtvl)
    }
  }
})
