<template>
  <div class="w-full">
<Dialog v-model:visible="sct" :style="{ width: '100vw' }" position="top" :modal="true">
<Toast position="top-center" styleClass="bg-grey"/>
    <div class="card">
      <Steps :model="stitems" :readonly="false" class="p-2">
        <template #item="{item}">
            <div class="font-bold w-full justify-content-center"><span :class="step==item.to?'text-green-700 md:flex':'md:flex'"><div><i :class="item.icon"></i></div><div> {{item.label}}</div></span></div>
        </template>
      </Steps>
    </div>
    <div class="grid" v-if="step==0">
    <div class="col-12 md:col-9">
    <DataTable ref="dttable" :value="lpdcode" class="editable-cells-table" :rows="100" editMode="cell"  @cell-edit-complete="onCellEditComplete"
    dataKey="code" widgetVar="cellProducts" :metaKeySelection="false" :loading="loading">
            <template #header>
                 <div class="flex justify-content-left align-items-left">
                    <h5 class="m-0">Giỏ hàng</h5>
                 </div>
            </template>
            <template #empty>
                Giỏ hàng trống!
            </template>
            <template #loading>
                Đang tải sản phẩm, dịch vụ...
            </template>
            <Column headerStyle="width: 2rem" field="code">
              <template #body="{data}">
                <Button @click="delscitem(data.code)" icon="pi pi-trash" class="p-button-danger"/>
              </template>
            </Column>
            <Column field="title" header="Sản phẩm" sortable style="min-width: 14rem" >
                <template #body="{data}">
                    {{data.title}}
                </template>
            </Column>
            <Column field="tmpprice" header="Đơn giá" style="min-width: 10rem">
                <template #body="slotProps">
                    {{formatCurrency(slotProps.data.tmpprice)}}
                </template>
            </Column>
            <Column field="nuser" header="Số lượng" style="min-width: 8rem">
                <template #body="{data,field}">
                    <InputNumber v-model="data[field]" class="p-column-filter" :min="10" />
                </template>
                <template #editor="{ data, field }">
                  <div class="p-input-filled" >
                    <InputNumber v-model="data[field]" autofocus class="p-column-filter" :min="10"/>
                  </div>
                </template>
            </Column>
            <Column field="tg" header="Thời gian (Tháng)" style="min-width: 10rem">
                <template #body="slotProps">
                        {{slotProps.data.tg}}
                    </template>
            </Column>
            <Column field="totalprice" header="Thành tiền" style="min-width: 10rem">
                <template #body="slotProps">
                <div v-if="!slotProps.data.kmprice">{{formatCurrency(slotProps.data.totalprice)}}</div>
                    <div v-else>
                    <div class="line-through">{{formatCurrency(slotProps.data.totalprice)}}</div>
                    <div>{{formatCurrency(slotProps.data.kmprice)}}</div>
                    </div>
                </template>
            </Column>
        </DataTable>
        <DataTable :value="lpdcodes"  :editable="true" class="editable-cells-table mt-4" :rows="10" editMode="cell"  @cell-edit-complete="onCellEditComplete" @row-select-all="onRowSelectAll"
        dataKey="code" :metaKeySelection="false" v-model:selection="lsldata" @rowSelect="onRowSelect" @rowUnselect="onRowUnselect" :loading="loading">
            <template #header>
                 <div class="flex justify-content-left align-items-left">
                    <h5 class="m-0">Sản phẩm dịch vụ khác</h5>
                 </div>
            </template>
            <template #empty>
                Không tìm thấy sản phẩm.
            </template>
            <template #loading>
                Đang tải sản phẩm, dịch vụ...
            </template>
            <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
            <Column field="title" header="Sản phẩm" sortable style="min-width: 14rem" >
                <template #body="{data}">
                    {{data.title}}
                </template>
            </Column>
            <Column field="tmpprice" header="Đơn giá" style="min-width: 10rem">
                <template #body="slotProps">
                    {{formatCurrency(slotProps.data.tmpprice)}}
                </template>
            </Column>
            <Column field="nuser" header="Số lượng" style="min-width: 8rem"  :editable="true">
                <template #body="{data,field}">
                    {{ data[field] }}
                </template>
            </Column>
            <Column field="tg" header="Thời gian (Tháng)" style="min-width: 10rem">
                <template #body="slotProps">
                        {{slotProps.data.tg}}
                    </template>
            </Column>
            <Column field="totalprice" header="Thành tiền" style="min-width: 10rem">
                <template #body="slotProps">
                    {{formatCurrency(slotProps.data.totalprice)}}
                </template>
            </Column>
        </DataTable>
    </div>
    <div class="col-12 md:col-3">
      <div class="fixed bottom-0 md:sticky md:top-0 w-12 p-2 bg-green-400">
        <ToggleButton class="sm:block md:hidden bg-gray-400 right-0" v-model="checked" onIcon="pi pi-angle-double-down" offIcon="pi pi-angle-double-up" @click="togglers"/>
        <div>Tổng tiền: {{formatCurrency(lresults.total)}}</div>
        <div :class="showbl">
        <div>Mã Khuyến mãi:<br/> <InputText v-model="lresults.kmcode" @blur="updatediscount"/></div>
        <div>Khuyến mãi: {{formatCurrency(lresults.discount)}}</div>
        <div>Mã Tư vấn:<br/> <InputText v-model="lresults.tvcode" /></div>
        <div>Tiền phải trả: {{formatCurrency(lresults.final)}}</div>
        </div>
        <div class="mt-2"><Button class="p-button-warning" :label="'Tiếp tục'" @click="ctnorder"/></div>
      </div>
    </div>
    <div>
    <Divider />
    </div>
    </div>
    <div class="grid" v-else-if="step==1">
      <div class="">
        <Ttcn @jumpnext="jumpnext" :gdata="gdata"/>
      </div>
    </div>
    <div class="grid" v-else-if="step==2" >
        <Xndh @jumpnext="jumpnext"  :gdata="gdata"  :gttcn="gttcn"/>
    </div>
    <div class="grid" v-else-if="step==3" >
      <h1>Cảm ơn bạn đã chia sẻ thông tin tới bạn bè!</h1>
      <h4>Bạn sẽ nhận được chiết khấu cho lần thanh toán tiếp theo sau khi bạn của bạn đã sử dụng dịch vụ của chúng tôi.</h4>
    </div>
</Dialog>
</div>
</template>
<script>
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Divider from 'primevue/divider'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import ToggleButton from 'primevue/togglebutton'
import Steps from 'primevue/steps'
import Ttcn from './thongtincanhan'
import Xndh from './xacnhandonhang'
import { Options, Vue } from 'vue-class-component'
import { ref, reactive, defineComponent } from 'vue'
import { useToast } from 'primevue/usetoast'
import Toast from 'primevue/toast'
import { uDiscount } from '@/api/datasource'
import { formatCurrency } from '@/utils/fslist'
export default defineComponent({
  name: 'giohangfloat',
  components: {
    Dialog, Button, InputText, Divider, DataTable, Column, InputNumber, Steps, Ttcn, Xndh, Toast, ToggleButton
  },
  props: {
    lang: {
      type: String,
      default: 'vi'
    }
  },
  setup () {
    const toast = useToast()
    return {
      msg: toast
    }
  },
  data () {
    return {
      loading: false,
      sct: false,
      step: 0,
      checked: false,
      showbl: 'hidden md:block',
      stitems: [{
        label: 'Giỏ hàng',
        icon: 'pi pi-fw pi-shopping-cart',
        to: 0
      },
      {
        separator: true
      },
      {
        label: 'Thông tin',
        icon: 'pi pi-fw pi-user-edit',
        to: 1
      },
      {
        label: 'Xác nhận',
        icon: 'pi pi-fw pi-check-circle',
        to: 2
      }],
      lsldata: ref([]),
      lpdcode: ref([]),
      lpdcodes: ref([]),
      gdata: ref({}),
      gttcn: ref({}),
      lresults: ref({ total: 0, discount: 0, final: 0, kmcode: '', tvcode: '' }),
      lQuery: {
        lang: 'vi'
      },
      lpages: [{ code: 'home', slug: '' }, { code: 'congty', slug: 'cong-ty' }]
    }
  },
  watch: {
    lang (nvl, ovl) { this.lQuery.lang = nvl }
  },
  created () {
    this.gList()
  },
  methods: {
    async gList () {
      await this.$store.dispatch('product/l', this.lQuery).then((res) => {
        if (res.data.data.data) {
          this.lpdcodes = res.data.data.data
          this.lpdcodes.forEach((it) => {
            it.description = ''
            it.shortdesc = ''
            it.created_at = ''
            it.updated_at = ''
            it.nuser = 1
            it.totalprice = it.price
            it.tmpprice = it.price
            if (it.pricegroup) {
              it.gprice = JSON.parse(it.pricegroup)
            }
            it.tg = 1
            if (it.gprice) {
              it.tg = it.gprice[0].tg
            }
          })
        }
      })
    },
    openshopcart (vl) {
      this.sct = true
      if (vl.kmcode) {
        this.lresults.kmcode = vl.kmcode
      }
      if (vl.stype === 0 && vl.pdcode) {
        var pdcode = vl.pdcode
        if (pdcode) {
          var sid = this.lpdcodes.findIndex((v) => v.code === pdcode)
          if (sid !== -1) {
            var nuser = 1
            var tg = 1
            var price = 0
            if (vl.nuser) {
              nuser = vl.nuser
            }
            this.addtocard(this.lpdcodes[sid], nuser, sid)
            var tmpdiscount = sessionStorage.getItem('st-kmcode')
            if (tmpdiscount) {
              this.lresults.kmcode = tmpdiscount
            }
            this.computeddata()
            this.updatediscount()
            this.step = 0
          }
        }
      } else if (vl.stype === 1) {
        this.onRowSelectAll()
      }
    },
    onCellEditComplete (event) {
      const { data, newValue, field, index } = event
      var sid = index
      switch (field) {
        case 'nuser':
          if (this.isPositiveInteger(newValue) && data[field] !== newValue) {
            this.addtocard(data, newValue, sid)
            this.computeddata()
            this.updatediscount()
          } else {
            event.preventDefault()
          }
          break
      }
    },
    isPositiveInteger (val) {
      let str = String(val)
      str = str.trim()
      if (!str) {
        return false
      }
      str = str.replace(/^0+/, '') || '0'
      var n = Math.floor(Number(str))
      return n !== Infinity && String(n) === str && n >= 0
    },
    addtocard (data, newValue, sid) {
      data.nuser = newValue
      var tmpprice = data.price
      var tmpmonth = 1
      if (data.pricetype === '620220316031501') {
        data.gprice.forEach((vl) => {
          tmpmonth = vl.tg
          if ((newValue >= vl.minuser && newValue < vl.maxuser) || newValue >= vl.minuser) {
            tmpprice = vl.price
          }
        })
      }
      data.tmpprice = tmpprice
      data.totalprice = newValue * tmpprice * tmpmonth
      if (this.lpdcode.length > 0) {
        var gid = this.lpdcode.findIndex((v) => v.code === data.code)
        if (gid === -1) {
          this.lpdcode.push(data)
          this.lpdcodes.splice(sid, 1)
        }
      } else {
        this.lpdcode.push(data)
        this.lpdcodes.splice(sid, 1)
      }
    },
    computeddata () {
      this.lresults.total = 0
      this.lpdcode.forEach((it) => {
        this.lresults.total += it.totalprice
      })
      this.lresults.final = this.lresults.total
    },
    onRowSelectAll (event) {
      if (this.lpdcodes) {
        this.lpdcodes.forEach((it) => {
          var data = it
          var tmpprice = data.price
          var tmpmonth = 1
          if (data.gprice) {
            data.gprice.forEach((vl) => {
              tmpmonth = vl.tg
              if ((data.nuser >= vl.minuser && data.nuser < vl.maxuser) || data.nuser >= vl.minuser) {
                tmpprice = vl.price
              }
            })
          }
          data.tmpprice = tmpprice
          data.totalprice = data.nuser * tmpprice * tmpmonth
          if (this.lpdcode.length > 0) {
            var gid = this.lpdcode.findIndex((v) => v.code === it.code)
            if (gid === -1) {
              this.lpdcode.push(data)
            }
          } else {
            this.lpdcode.push(data)
          }
        })
        this.lpdcodes = []
        this.computeddata()
        this.updatediscount()
      }
    },
    onRowSelect (event) {
      this.addtocard(event.data, 1, event.index)
      this.computeddata()
      this.updatediscount()
      this.lsldata = []
    },
    onRowUnselect (event) {
      console.log(event)
    },
    delscitem (code) {
      var gid = this.lpdcode.findIndex(vl => vl.code === code)
      if (gid !== -1) {
        var data = this.lpdcode[gid]
        data.nuser = 1
        data.totalprice = data.price
        data.tmpprice = data.price
        this.lpdcodes.push(data)
        this.lpdcode.splice(gid, 1)
        this.computeddata()
        this.updatediscount()
      }
    },
    updatediscount () {
      if (this.lresults.kmcode) {
        var gemail = ''
        gemail = sessionStorage.getItem('st-email')
        uDiscount({ kmcode: this.lresults.kmcode, email: gemail }).then((res) => {
          if (res.data.code === 200) {
            var kmdata = res.data.data.ldata
            var startdate = new Date()
            var startkmdate = new Date(kmdata.startdate + ' ' + kmdata.starttime)
            var endkmdate = new Date(kmdata.startdate + ' ' + kmdata.endtime)
            endkmdate.setDate(endkmdate.getDate() + parseInt(kmdata.tlhieuluc))
            if (startdate.getTime() >= startkmdate.getTime() && startdate.getTime() < endkmdate.getTime()) {
              this.lresults.discount = 0
              this.lpdcode.forEach((lpditem) => {
                if (kmdata.product.includes(lpditem.code)) {
                  var tmpdiscount = (parseInt(kmdata.value) * parseFloat(lpditem.totalprice)) / 100
                  lpditem.kmprice = lpditem.totalprice - tmpdiscount
                  this.lresults.discount += tmpdiscount
                }
              })
              this.lresults.final -= this.lresults.discount
            }
          }
        })
      }
    },
    ctnorder () {
      this.step = 1
      this.gdata = { prd: this.lpdcode, result: this.lresults }
    },
    togglers () {
      if (this.checked) {
        this.showbl = 'block'
      } else {
        this.showbl = 'hidden md:block'
      }
    },
    jumpnext (vl) {
      if (vl.code === 1) {
        this.step = 2
        this.gttcn = vl.data
      } else if (vl.code === 0) {
        this.step = 0
      } else if (vl.code === 3) {
        this.step = 3
      } else {
        this.msg.add({ severity: 'warning', summary: 'Thông báo', detail: 'Một số thông tin không chính xác!', life: 3000 })
      }
    },
    formatCurrency
  }
})
</script>
<style lang="scss" scoped>
::v-deep(.editable-cells-table td.p-cell-editing) {
    padding-top: 0;
    padding-bottom: 0;
}
::v-deep(td.p-column-title) {
    padding-left: 5px;
}
</style>
