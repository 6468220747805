<template>
<div class="bottom-0">
  <router-view @opencontact="opencontact" @openshopcart="openshopcart" @loadfooter="loadfooter" @stopshowsurvey="stopshowsurvey"/>
  <LeftFloat @utracking="utracking" />
  <RightFloat @utracking="utracking" />
  <Dialog v-model:visible="dsp" ref="ctform" :modal="true" @hide="closecontact">
  <ContactFormdnm :psend="psend" :esend="esend" @close="closecontact" />
  </Dialog>
  <Dialog v-model:visible="dspsv" :breakpoints="{ '1024px': '50vw', '960px': '75vw', '640px': '100vw' }" :style="{ width: '50vw' }" ref="svform" :modal="true" @hide="closecontact">
  <SurveyFloat :psend="psend" @close="closecontact" @donesurvey="donesurvey" />
  </Dialog>
  <ChatBot @stopshowsurvey="stopshowsurvey"/>
  <LeftCornerFloat />
  <GiohangFloat :lang="lang" ref="ghfloat" @utracking="utracking" />
</div>
</template>
<script type="ts">
import ContactFormdnm from '@/layouts/other/contactfloatdnm.vue' // @ is an alias to /src
import Dialog from 'primevue/dialog'
import LeftFloat from '@/layouts/other/leftfloat.vue' // @ is an alias to /src
import RightFloat from '@/layouts/other/rightfloat.vue' // @ is an alias to /src
import ChatBot from '@/layouts/other/chatbot.vue' // @ is an alias to /src
import LeftCornerFloat from '@/layouts/other/leftcornerfloat.vue'
import GiohangFloat from '@/layouts/other/giohangfloat.vue'
import SurveyFloat from '@/layouts/other/surveyfloat.vue'
import { sdSurvey } from '@/api/datasource'
import { ctntrack } from '@/api/tracking'
export default {
  components: { SurveyFloat, LeftFloat, RightFloat, ChatBot, Dialog, LeftCornerFloat, GiohangFloat, ContactFormdnm },
  data () {
    return {
      dsp: false,
      dspsv: false,
      atpopup: true,
      tmptime: null,
      svdone: false,
      localTime: '',
      psend: {},
      esend: ''
    }
  },
  created () {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.showLocaleTime)
    })
  },
  methods: {
    showLocaleTime: function () {
      var vm = this
      clearInterval(this.tmptime)
      this.tmptime = setInterval(function () {
        if (vm.atpopup && !vm.svdone) {
          vm.dspsv = true
        }
      }, 9000)
    },
    donesurvey (vl) {
      if (vl) {
        this.svdone = true
        var gss = sessionStorage.getItem('st-acpage')
        const sendData = { svcode: 'kskhmsp', rsession: gss }
        sdSurvey(sendData).then((res) => {
          this.closecontact(3)
        })
      }
    },
    stopshowsurvey () {
      this.atpopup = false
      clearInterval(this.tmptime)
    },
    opencontact (vl) {
      this.psend = vl
      this.esend = vl.email
      this.dsp = true
      this.atpopup = false
      clearInterval(this.tmptime)
    },
    closecontact (gd) {
      this.dsp = false
      this.dspsv = false
      clearInterval(this.tmptime)
      if (!this.svdone) {
        this.atpopup = true
      }
      if (gd === 3) {
        this.$router.replace('/Sharing')
      }
    },
    openshopcart (vl) {
      this.atpopup = false
      clearInterval(this.tmptime)
      this.$refs.ghfloat.openshopcart(vl)
    },
    utracking (vl) {
      var gss = sessionStorage.getItem('st-acpage')
      var to = this.$route
      var ptitle = to.meta.title
      if (vl.pagetitle) {
        ptitle = vl.pagetitle
      }
      var gtdata = { rsession: gss, pageid: to.path, pagetitle: ptitle, elementid: vl.eid }
      ctntrack(gtdata)
    },
    loadfooter () {
      this.$emit('sload', true)
    }
  }
}
</script>
